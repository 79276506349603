import React, { useRef } from 'react';

function Step7Condominio({ url }) {
    const handleUrl = () => {
        window.location.href = `/${url}`;
    }
    return (
        <>
            <h6 class="ParaCondominio mx-auto mb-3">PARA SEU CONDOMÍNIO</h6>

            <form class="mx-auto FormDados2">
                <div class="row">
                    <div class="col-12 my-4">
                        <h2><strong>OBRIGADO PELAS INFORMAÇÕES!</strong><br />
                            EM BREVE UM DE NOSSOS CONSULTORES ENTRARÁ EM CONTATO PARA SEQUÊNCIA DA OPERAÇÃO</h2>
                    </div>
                    <div class="col-12 mt-3">
                        <button type="button" onClick={handleUrl} class="btn btn-secondary">realizar uma nova simulação</button>
                    </div>
                </div>
            </form>


        </>
    );
}

export default Step7Condominio;
