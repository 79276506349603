import React from 'react';

function Step7Empresa({ url }) {
    const handleUrl = () => {
        window.location.href = `/${url}`;
    }
    return (
        <>
            <h6 className="ParaEmpresa mx-auto mb-3">PARA SUA EMPRESA</h6>
            <form className="mx-auto FormDados2">
                <div className="row">
                    <div className="col-12 my-4">
                        <h2><strong>OBRIGADO PELAS INFORMAÇÕES!</strong><br />
                            EM BREVE UM DE NOSSOS CONSULTORES ENTRARÁ EM CONTATO PARA SEQUÊNCIA DA OPERAÇÃO</h2>

                    </div>
                    <div className="col-12 mt-3">
                        <button type="button" onClick={handleUrl} className="btn btn-secondary">realizar uma nova simulação</button>
                    </div>
                </div>
            </form>

        </>
    );
}

export default Step7Empresa;
