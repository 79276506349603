import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

function Step3({ advanceStep, submissionId }) {
    const [showDependentes, setShowDependentes] = useState(false);
    const formik = useFormik({
        initialValues: {
            dataNascimento: '',
            identidade: '',
            orgaoEmissor: '',
            sexo: '',
            naturalidade: '',
            estadoCivil: '',
            escolaridade: '',
            dependentes: '',
            quantidadeDependentes: 0,
            pep: '',
            descricaoPep: '',
            nomeMae: '',
            nomePai: ''
        },
        validationSchema: Yup.object({
            dataNascimento: Yup.date().required("Data de nascimento obrigatória"),
            identidade: Yup.string().required('A identidade é obrigatória'),
            orgaoEmissor: Yup.string().required('O órgão emissor é obrigatório'),
            sexo: Yup.string().required('A seleção de sexo é obrigatória'),
            naturalidade: Yup.string().required('A naturalidade é obrigatória'),
            estadoCivil: Yup.string().required('O estado civil é obrigatório'),
            escolaridade: Yup.string().required('A escolaridade é obrigatória'),
            dependentes: Yup.string().required('A indicação de dependentes é obrigatória'),
            quantidadeDependentes: Yup.number().when('dependentes', {
                is: 'sim',
                then: (schema) => schema.required('A quantidade de dependentes é obrigatória').min(1, 'A quantidade deve ser no mínimo 1'),
                otherwise: (schema) => schema.nullable()
            }),
            pep: Yup.string().required('A indicação sobre pessoa politicamente exposta é obrigatória'),
            descricaoPep: Yup.string().when('pep', {
                is: 'sim',
                then: schema => schema.required('A descrição é obrigatória').min(10, 'A descrição deve ter no mínimo 10 caracteres'),
                otherwise: schema => schema.nullable()
            }),
            nomeMae: Yup.string().required('O nome da mãe é obrigatório').min(6, 'O nome da mãe deve ter no mínimo 6 caracteres'),
            nomePai: Yup.string().required('O nome do pai é obrigatório').min(6, 'O nome do pai deve ter no mínimo 6 caracteres'),
        }),
        onSubmit: (values) => {
            console.log('Submitting values:', values);
            const payload = {
                ...values,
                id: submissionId,
                quantidadeDependentes: values.dependentes === 'sim' ? values.quantidadeDependentes : 0
            };
            delete payload.dependentes;

            axios.post(`${process.env.REACT_APP_API_URL}/precadastro/step3`, payload)
                .then(response => {
                    console.log('Dados enviados com sucesso:', response.data);
                    advanceStep();  // Avança para o próximo passo após sucesso
                })
                .catch(error => {
                    console.error('Erro ao enviar dados:', error);
                });
        }
    });

    return (
        <form className="mx-auto FormDados2" onSubmit={formik.handleSubmit}>
            <h1>CADASTRO</h1>
            <p>Favor informar seus dados abaixo para seguirmos com a solicitação da sua operação.</p>
            <div className="row mt-3">
                {/* Campos de Identidade e Orgão Emissor */}
                <div className="col-md-12">
                    <label htmlFor="" class="mt-3">Digite sua Data de Nascimento:</label>
                    <input type="date" className="form-control text-center" id="dataNascimento" placeholder="Data de Nascimento" {...formik.getFieldProps('dataNascimento')} />
                    {formik.touched.dataNascimento && formik.errors.dataNascimento ? <div className="text-danger">{formik.errors.dataNascimento}</div> : null}
                </div>
                <div className="col-md-4">
                    <input type="text" className="form-control" id="identidade" placeholder="Identidade" {...formik.getFieldProps('identidade')} />
                    {formik.touched.identidade && formik.errors.identidade ? <div className="text-danger">{formik.errors.identidade}</div> : null}
                </div>
                <div className="col-md-4">
                    <input type="text" className="form-control" id="orgaoEmissor" placeholder="Orgão emissor" {...formik.getFieldProps('orgaoEmissor')} />
                    {formik.touched.orgaoEmissor && formik.errors.orgaoEmissor ? <div className="text-danger">{formik.errors.orgaoEmissor}</div> : null}
                </div>

                {/* Campo de Sexo */}
                <div className="col-md-4">
                    <select className="form-select" {...formik.getFieldProps('sexo')}>
                        <option value="">Sexo</option>
                        <option value="M">Masculino</option>
                        <option value="F">Feminino</option>
                    </select>
                    {formik.touched.sexo && formik.errors.sexo ? <div className="text-danger">{formik.errors.sexo}</div> : null}
                </div>

                {/* Campo de Naturalidade */}
                <div className="col-md-4">
                    <input type="text" className="form-control" id="naturalidade" placeholder="Naturalidade" {...formik.getFieldProps('naturalidade')} />
                    {formik.touched.naturalidade && formik.errors.naturalidade ? <div className="text-danger">{formik.errors.naturalidade}</div> : null}
                </div>

                {/* Campos de Estado Civil */}
                <div className="col-md-4">
                    <select className="form-select" {...formik.getFieldProps('estadoCivil')}>
                        <option value="">Estado Civil</option>
                        <option value="C">CASADO(A)</option>
                        <option value="S">SOLTEIRO(A)</option>
                        <option value="D">DIVORCIADO(A)</option>
                        <option value="J">SEPARADO(A) JUDICIALMENTE</option>
                        <option value="Q">DESQUITADO(A)</option>
                        <option value="V">VIÚVO(A)</option>
                        <option value="U">UNIÃO ESTÁVEL</option>
                    </select>
                    {formik.touched.estadoCivil && formik.errors.estadoCivil ? (
                        <div className="text-danger">{formik.errors.estadoCivil}</div>
                    ) : null}
                </div>

                {/* Campo de Escolaridade */}
                <div className="col-md-4">
                    <select className="form-select" {...formik.getFieldProps('escolaridade')}>
                        <option value="">Escolaridade</option>
                        <option value="1">ENSINO FUNDAMENTAL INCOMPLETO</option>
                        <option value="2">ENSINO FUNDAMENTAL COMPLETO</option>
                        <option value="3">ENSINO MÉDIO INCOMPLETO</option>
                        <option value="4">ENSINO MÉDIO COMPLETO</option>
                        <option value="5">SUPERIOR INCOMPLETO</option>
                        <option value="6">SUPERIOR COMPLETO</option>
                        <option value="7">SEM ESCOLARIDADE</option>
                    </select>
                    {formik.touched.escolaridade && formik.errors.escolaridade ? (
                        <div className="text-danger">{formik.errors.escolaridade}</div>
                    ) : null}
                </div>

                {/* Campo de Dependentes */}
                <div className="col-md-12 mt-3">
                    <p className="mb-0">Dependentes</p>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="dependentes" id="dependentesSim" value="sim" onChange={(e) => {
                            formik.handleChange(e);
                            setShowDependentes(true);
                        }} checked={formik.values.dependentes === 'sim'} />
                        <label className="form-check-label" htmlFor="dependentesSim">SIM</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="dependentes" id="dependentesNao" value="nao" onChange={(e) => {
                            formik.handleChange(e);
                            setShowDependentes(false);
                            formik.setFieldValue('quantidadeDependentes', 0);
                        }} checked={formik.values.dependentes === 'nao'} />
                        <label className="form-check-label" htmlFor="dependentesNao">NÃO</label>
                    </div>
                    {formik.touched.dependentes && formik.errors.dependentes ? <div className="text-danger">{formik.errors.dependentes}</div> : null}
                </div>

                {showDependentes && (
                    <div className="col-md-12 mt-2">
                        <input type="number" className="form-control" id="quantidadeDependentes" placeholder="Quantidade de dependentes" {...formik.getFieldProps('quantidadeDependentes')} />
                        {formik.touched.quantidadeDependentes && formik.errors.quantidadeDependentes ? <div className="text-danger">{formik.errors.quantidadeDependentes}</div> : null}
                    </div>
                )}

                {/* Campo de Pessoa Politicamente Exposta */}
                <div className="col-md-12 mt-4 mb-3">
                    <p className="mb-0">Pessoa Politicamente Exposta</p>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="pep"
                            id="pepSim"
                            value="sim"
                            onChange={formik.handleChange}
                            checked={formik.values.pep === 'sim'}
                        />
                        <label className="form-check-label" htmlFor="pepSim">SIM</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="pep"
                            id="pepNao"
                            value="nao"
                            onChange={formik.handleChange}
                            checked={formik.values.pep === 'nao'}
                        />
                        <label className="form-check-label" htmlFor="pepNao">NÃO</label>
                    </div>
                    {formik.touched.pep && formik.errors.pep ? <div className="text-danger">{formik.errors.pep}</div> : null}
                    {formik.values.pep === 'sim' && (
                        <div className="col-md-12 mt-3">
                            <textarea className="form-control mb-10" id="descricaoPep" rows="2" placeholder="Descreva em poucas palavras" {...formik.getFieldProps('descricaoPep')}></textarea>
                            {formik.touched.descricaoPep && formik.errors.descricaoPep ? <div className="text-danger">{formik.errors.descricaoPep}</div> : null}
                        </div>
                    )}
                </div>

                {/* Campos para Nomes dos Pais */}
                <div className="col-md-6">
                    <input type="text" className="form-control" id="nomeMae" placeholder="Nome da mãe" {...formik.getFieldProps('nomeMae')} />
                    {formik.touched.nomeMae && formik.errors.nomeMae ? <div className="text-danger">{formik.errors.nomeMae}</div> : null}
                </div>
                <div className="col-md-6">
                    <input type="text" className="form-control" id="nomePai" placeholder="Nome do pai" {...formik.getFieldProps('nomePai')} />
                    {formik.touched.nomePai && formik.errors.nomePai ? <div className="text-danger">{formik.errors.nomePai}</div> : null}
                </div>

                {/* Botão para Avançar */}
                <div className="col-md-12 col-lg-12 mt-3">
                    <button type="submit" className="btn btn-primary">Avançar</button>
                </div>
            </div>
        </form>
    );
}

export default Step3;
