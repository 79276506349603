import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

function Step5({ advanceStep, submissionId }) {
    const [showAnosCasaPropria, setShowAnosCasaPropria] = useState(false);

    const formik = useFormik({
        initialValues: {
            cep: '',
            logradouro: '',
            numero: '',
            complemento: '',
            bairro: '',
            cidade: '',
            estado: '',
            contaDeLuz: null,
            identidadeFrente: null,
            identidadeVerso: null,
            compIPTU: null,
            termoDeUso: false,
            compartilhamentoDados: false,
            termoFidelidade: false,
            imovelProprio: '',
            anosCasaPropria: ''
        },
        validationSchema: Yup.object({
            cep: Yup.string().required('CEP é obrigatório'),
            logradouro: Yup.string().required('Logradouro é obrigatório'),
            numero: Yup.string().required('Número é obrigatório'),
            complemento: Yup.string(),
            bairro: Yup.string().required('Bairro é obrigatório'),
            cidade: Yup.string().required('Cidade é obrigatória'),
            estado: Yup.string().required('Estado é obrigatório'),
            contaDeLuz: Yup.mixed().required('O upload da conta de aluguel é obrigatório'),
            identidadeFrente: Yup.mixed().required('O upload da frente da identidade é obrigatório'),
            identidadeVerso: Yup.mixed().required('O upload do verso da identidade é obrigatório'),
            compIPTU: Yup.mixed().required('O upload do IPTU é obrigatório'),
            imovelProprio: Yup.boolean().oneOf([true, false], 'A informação do tipo de imóvel é obrigatória').required('A informação do tipo de imóvel é obrigatória'),
            anosCasaPropria: Yup.number().when('imovelProprio', {
                is: 'true',
                then: Yup.number().required('Anos na casa própria é obrigatório').positive('Deve ser um número positivo').integer('Deve ser um número inteiro'),
            }),
        }),
        onSubmit: (values) => {

            console.log("submitting");
            console.log("submit values: " + JSON.stringify(values));
            if (values.imovelProprio === '') {
                formik.setFieldTouched('imovelProprio', true, true);
                return;
            }

            const formData = new FormData();
            formData.append('id', submissionId);
            formData.append('cep', values.cep);
            formData.append('logradouro', values.logradouro);
            formData.append('numero', values.numero);
            formData.append('complemento', values.complemento);
            formData.append('bairro', values.bairro);
            formData.append('cidade', values.cidade);
            formData.append('estado', values.estado);
            formData.append('contaDeLuz', values.contaDeLuz);
            formData.append('identidadeFrente', values.identidadeFrente);
            formData.append('identidadeVerso', values.identidadeVerso);
            formData.append('compIPTU', values.compIPTU);
            formData.append('termoDeUso', values.termoDeUso);
            formData.append('compartilhamentoDados', values.compartilhamentoDados);
            formData.append('termoFidelidade', values.termoFidelidade);
            formData.append('imovelProprio', values.imovelProprio === 'true');
            if (values.imovelProprio === 'true') {
                formData.append('anosCasaPropria', values.anosCasaPropria);
            }

            

            axios.post(`${process.env.REACT_APP_API_URL}/precadastro/step5`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    console.log('Dados enviados com sucesso:', response.data);
                    advanceStep();
                })
                .catch(error => {
                    console.error('Erro ao enviar dados:', error);
                });
        }
    });

    return (
        <form className="mx-auto FormDados2" onSubmit={formik.handleSubmit}>
            <h1>CADASTRO</h1>
            <p>Favor informar seus dados abaixo para seguirmos com a solicitação da sua operação.</p>
            <h3 className="mt-4">ENDEREÇO RESIDENCIAL</h3>
            <p>Deve ser o mesmo do comprovante de endereço enviado abaixo.</p>
            <div className="row mt-3">
                <div className="col-md-3">
                    <input type="text" className="form-control" placeholder="CEP" {...formik.getFieldProps('cep')} />
                    {formik.touched.cep && formik.errors.cep ? <div className="text-danger">{formik.errors.cep}</div> : null}
                </div>
                <div className="col-md-7">
                    <input type="text" className="form-control" placeholder="Logradouro" {...formik.getFieldProps('logradouro')} />
                    {formik.touched.logradouro && formik.errors.logradouro ? <div className="text-danger">{formik.errors.logradouro}</div> : null}
                </div>
                <div className="col-md-2">
                    <input type="text" className="form-control" placeholder="Número" {...formik.getFieldProps('numero')} />
                    {formik.touched.numero && formik.errors.numero ? <div className="text-danger">{formik.errors.numero}</div> : null}
                </div>
                <div className="col-md-2">
                    <input type="text" className="form-control" placeholder="Complemento" {...formik.getFieldProps('complemento')} />
                </div>
                <div className="col-md-3">
                    <input type="text" className="form-control" placeholder="Bairro" {...formik.getFieldProps('bairro')} />
                    {formik.touched.bairro && formik.errors.bairro ? <div className="text-danger">{formik.errors.bairro}</div> : null}
                </div>
                <div className="col-md-4">
                    <input type="text" className="form-control" placeholder="Cidade" {...formik.getFieldProps('cidade')} />
                    {formik.touched.cidade && formik.errors.cidade ? <div className="text-danger">{formik.errors.cidade}</div> : null}
                </div>
                <div className="col-md-3">
                    <select
                        className="form-control"
                        onBlur={() => formik.setFieldTouched('estado', true, true)}
                        id="estado"
                        {...formik.getFieldProps('estado')}
                    >
                        <option value="">Selecione estado</option>
                        <option value="AC">Acre</option>
                        <option value="AL">Alagoas</option>
                        <option value="AP">Amapá</option>
                        <option value="AM">Amazonas</option>
                        <option value="BA">Bahia</option>
                        <option value="CE">Ceará</option>
                        <option value="DF">Distrito Federal</option>
                        <option value="ES">Espírito Santo</option>
                        <option value="GO">Goiás</option>
                        <option value="MA">Maranhão</option>
                        <option value="MT">Mato Grosso</option>
                        <option value="MS">Mato Grosso do Sul</option>
                        <option value="MG">Minas Gerais</option>
                        <option value="PA">Pará</option>
                        <option value="PB">Paraíba</option>
                        <option value="PR">Paraná</option>
                        <option value="PE">Pernambuco</option>
                        <option value="PI">Piauí</option>
                        <option value="RJ">Rio de Janeiro</option>
                        <option value="RN">Rio Grande do Norte</option>
                        <option value="RS">Rio Grande do Sul</option>
                        <option value="RO">Rondônia</option>
                        <option value="RR">Roraima</option>
                        <option value="SC">Santa Catarina</option>
                        <option value="SP">São Paulo</option>
                        <option value="SE">Sergipe</option>
                        <option value="TO">Tocantins</option>
                    </select>
                    {formik.touched.estado && formik.errors.estado ? (
                        <div className="text-danger">{formik.errors.estado}</div>
                    ) : null}



                </div>
                <div class="d-none">
                    <div className="mx-auto mt-3">
                        <p className="mb-0">O imóvel é:</p>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="empregado" id="inlineRadio1" value="Sim" onChange={formik.handleChange} checked={formik.values.empregado === 'Sim'} />
                        <label className="form-check-label" htmlFor="inlineRadio1">Alugado</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="empregado" id="inlineRadio2" value="Não" onChange={formik.handleChange} checked={formik.values.empregado === 'Não'} />
                        <label className="form-check-label" htmlFor="inlineRadio2">Próprio</label>
                            </div>
                        </div>

                </div>
                <div className="mx-auto mt-3">
                    <p className="mb-0">O imóvel é:</p>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="imovelProprio"
                            id="inlineRadio1"
                            value="true"
                            onChange={(event) => {
                                formik.handleChange(event);
                                setShowAnosCasaPropria(true);
                            }}
                            checked={formik.values.imovelProprio === 'true'}
                        />
                        <label className="form-check-label" htmlFor="inlineRadio1">Próprio</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="imovelProprio"
                            id="inlineRadio2"
                            value="false"
                            onChange={(event) => {
                                formik.handleChange(event);
                                setShowAnosCasaPropria(false);
                            }}
                            checked={formik.values.imovelProprio === 'false'}
                        />
                        <label className="form-check-label" htmlFor="inlineRadio2">Alugado</label>
                    </div>
                    {formik.touched.imovelProprio && formik.errors.imovelProprio ? <div className="text-danger">{formik.errors.imovelProprio}</div> : null}
                    {showAnosCasaPropria && (
                        <div className="col-md-3 mt-3 mx-auto">
                            <label htmlFor="anosCasaPropria" className="form-label">Anos na Casa Própria</label>
                            <input
                                type="number"
                                className="form-control"
                                id="anosCasaPropria"
                                {...formik.getFieldProps('anosCasaPropria')}
                            />
                            {formik.touched.anosCasaPropria && formik.errors.anosCasaPropria ? (
                                <div className="text-danger">{formik.errors.anosCasaPropria}</div>
                            ) : null}
                        </div>
                    )}

                </div>
                <h3 className="mt-5">UPLOAD DE ARQUIVOS</h3>
                <div className="col-md-6 mt-3">
                    <label htmlFor="identidadeFrente" className="form-label"><strong>IDENTIDADE FRENTE</strong></label>
                    <input className="form-control" type="file" id="identidadeFrente" accept="image/jpeg, image/png" onChange={(event) => formik.setFieldValue('identidadeFrente', event.currentTarget.files[0])} />
                    {formik.touched.identidadeFrente && formik.errors.identidadeFrente ? <div className="text-danger">{formik.errors.identidadeFrente}</div> : null}
                </div>
                <div className="col-md-6 mt-3">
                    <label htmlFor="identidadeVerso" className="form-label"><strong>IDENTIDADE VERSO</strong></label>
                    <input className="form-control" type="file" id="identidadeVerso" accept="image/jpeg, image/png" onChange={(event) => formik.setFieldValue('identidadeVerso', event.currentTarget.files[0])} />
                    {formik.touched.identidadeVerso && formik.errors.identidadeVerso ? <div className="text-danger">{formik.errors.identidadeVerso}</div> : null}
                </div>
                <div className="col-md-6 mt-3">
                    <label htmlFor="contaDeLuz" className="form-label"><strong>COMPROVANTE DE ENDEREÇO</strong></label>
                    <input className="form-control" type="file" id="contaDeLuz" accept="image/jpeg, image/png" onChange={(event) => formik.setFieldValue('contaDeLuz', event.currentTarget.files[0])} />
                    {formik.touched.contaDeLuz && formik.errors.contaDeLuz ? <div className="text-danger">{formik.errors.contaDeLuz}</div> : null}
                </div>
                <div className="col-md-6 mt-3">
                    <label htmlFor="compIPTU" className="form-label"><strong>COMPROVANTE DE IPTU</strong></label>
                    <input className="form-control" type="file" id="compIPTU" accept="image/jpeg, image/png" onChange={(event) => formik.setFieldValue('compIPTU', event.currentTarget.files[0])} />
                    {formik.touched.compIPTU && formik.errors.compIPTU ? <div className="text-danger">{formik.errors.compIPTU}</div> : null}
                </div>
                
                <div className="col-12 mt-5">
                    <button type="submit" className="btn btn-primary">Finalizar</button>
                </div>
            </div>
        </form>
    );
}

export default Step5;
