import React from 'react';
function Step6({ url }) {

    const handleUrl = () => {
        window.location.href = `/${url}`;
    }

    return (
        <form class="mx-auto FormDados2">
            <div class="row">
                <div class="col-12 my-4">
                    <h2><strong>OBRIGADO PELAS INFORMAÇÕES!</strong><br />
                        EM BREVE UM DE NOSSOS CONSULTORES ENTRARÁ EM CONTATO PARA SEQUÊNCIA DA OPERAÇÃO</h2>
                </div>
                <div class="col-12 mt-3">
                    <button type="button" className="btn btn-secondary" onClick={handleUrl}>realizar uma nova simulação</button>
                </div>
            </div>
        </form>
    );
}

export default Step6;
