import React, { useState } from 'react';
import { useFormik } from 'formik';
import { object, string, number, date } from 'yup';
import axios from 'axios';
import InputMask from 'react-input-mask';

function Step4({ advanceStep, submissionId }) {
    const [formattedValue, setFormattedValue] = useState('');

    const handleCurrencyFormat = (event) => {
        let value = event.target.value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
        value = Number(value) / 100; // Converte o valor para decimal
        const formatted = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2
        }).format(value);

        setFormattedValue(formatted); // Atualiza o valor formatado no estado
        formik.setFieldValue('rendaMensal', value.toFixed(2)); // Atualiza o valor numérico no estado do Formik
    };

    const handleCEPFormat = (event) => {
        let value = event.target.value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
        if (value.length > 5) {
            value = value.slice(0, 5) + '-' + value.slice(5, 8); // Formata como 00000-000
        }
        formik.setFieldValue('cep', value); // Atualiza o valor no estado do Formik
    };

    const banks = [
        { id: '748', name: 'Banco Cooperativo Sicredi S.A.' },
        { id: '117', name: 'Advanced Cc Ltda' },
        { id: '172', name: 'Albatross Ccv S.A' },
        { id: '188', name: 'Ativa Investimentos S.A' },
        { id: '280', name: 'Avista S.A. Crédito, Financiamento e Investimento' },
        { id: '080', name: 'B&T Cc Ltda' },
        { id: '654', name: 'Banco A.J.Renner' },
        { id: '246', name: 'Banco ABC Brasil S.A.' },
        { id: '075', name: 'Banco ABN AMRO S.A' },
        { id: '121', name: 'Banco Agibank S.A.' },
        { id: '025', name: 'Banco Alfa S.A.' },
        { id: '641', name: 'Banco Alvorada S.A.' },
        { id: '065', name: 'Banco Andbank (Brasil) S.A.' },
        { id: '213', name: 'Banco Arbi S.A.' },
        { id: '096', name: 'Banco B3 S.A.' },
        { id: '024', name: 'Banco BANDEPE S.A.' },
        { id: '318', name: 'Banco BMG S.A.' },
        { id: '752', name: 'Banco BNP Paribas Brasil S.A.' },
        { id: '107', name: 'Banco BOCOM BBM S.A.' },
        { id: '063', name: 'Banco Bradescard S.A.' },
        { id: '036', name: 'Banco Bradesco BBI S.A.' },
        { id: '122', name: 'Banco Bradesco BERJ S.A.' },
        { id: '204', name: 'Banco Bradesco Cartões S.A.' },
        { id: '394', name: 'Banco Bradesco Financiamentos S.A.' },
        { id: '237', name: 'Banco Bradesco S.A.' },
        { id: '218', name: 'Banco BS2 S.A.' },
        { id: '208', name: 'Banco BTG Pactual S.A.' },
        { id: '336', name: 'Banco C6 S.A – C6 Bank' },
        { id: '473', name: 'Banco Caixa Geral – Brasil S.A.' },
        { id: '412', name: 'Banco Capital S.A.' },
        { id: '040', name: 'Banco Cargill S.A.' },
        { id: '368', name: 'Banco Carrefour' },
        { id: '266', name: 'Banco Cédula S.A.' },
        { id: '739', name: 'Banco Cetelem S.A.' },
        { id: '233', name: 'Banco Cifra S.A.' },
        { id: '745', name: 'Banco Citibank S.A.' },
        { id: '241', name: 'Banco Clássico S.A.' },
        { id: '756', name: 'Banco Cooperativo do Brasil S.A. – BANCOOB' },
        { id: '222', name: 'Banco Credit Agricole Brasil S.A.' },
        { id: '505', name: 'Banco Credit Suisse (Brasil) S.A.' },
        { id: '069', name: 'Banco Crefisa S.A.' },
        { id: '003', name: 'Banco da Amazônia S.A.' },
        { id: '083', name: 'Banco da China Brasil S.A.' },
        { id: '051', name: 'Banco de Desenvolvimento do Espírito Santo S.A.' },
        { id: '300', name: 'Banco de La Nacion Argentina' },
        { id: '495', name: 'Banco de La Provincia de Buenos Aires' },
        { id: '494', name: 'Banco de La Republica Oriental del Uruguay' },
        { id: '335', name: 'Banco Digio S.A' },
        { id: '001', name: 'Banco do Brasil S.A.' },
        { id: '047', name: 'Banco do Estado de Sergipe S.A.' },
        { id: '037', name: 'Banco do Estado do Pará S.A.' },
        { id: '041', name: 'Banco do Estado do Rio Grande do Sul S.A.' },
        { id: '004', name: 'Banco do Nordeste do Brasil S.A.' },
        { id: '196', name: 'Banco Fair Corretora de Câmbio S.A' },
        { id: '265', name: 'Banco Fator S.A.' },
        { id: '224', name: 'Banco Fibra S.A.' },
        { id: '626', name: 'Banco Ficsa S.A.' },
        { id: '094', name: 'Banco Finaxis S.A.' },
        { id: '612', name: 'Banco Guanabara S.A.' },
        { id: '012', name: 'Banco Inbursa S.A.' },
        { id: '604', name: 'Banco Industrial do Brasil S.A.' },
        { id: '653', name: 'Banco Indusval S.A.' },
        { id: '077', name: 'Banco Inter S.A.' },
        { id: '249', name: 'Banco Investcred Unibanco S.A.' },
        { id: '184', name: 'Banco Itaú BBA S.A.' },
        { id: '029', name: 'Banco Itaú Consignado S.A.' },
        { id: '479', name: 'Banco ItauBank S.A' },
        { id: '376', name: 'Banco J. P. Morgan S.A.' },
        { id: '074', name: 'Banco J. Safra S.A.' },
        { id: '217', name: 'Banco John Deere S.A.' },
        { id: '076', name: 'Banco KDB S.A.' },
        { id: '757', name: 'Banco KEB HANA do Brasil S.A.' },
        { id: '600', name: 'Banco Luso Brasileiro S.A.' },
        { id: '243', name: 'Banco Máxima S.A.' },
        { id: '720', name: 'Banco Maxinvest S.A.' },
        { id: '389', name: 'Banco Mercantil do Brasil S.A.' },
        { id: '370', name: 'Banco Mizuho do Brasil S.A.' },
        { id: '746', name: 'Banco Modal S.A.' },
        { id: '066', name: 'Banco Morgan Stanley S.A.' },
        { id: '456', name: 'Banco MUFG Brasil S.A.' },
        { id: '007', name: 'Banco Nacional de Desenvolvimento Econômico e Social – BNDES' },
        { id: '169', name: 'Banco Olé Bonsucesso Consignado S.A.' },
        { id: '111', name: 'Banco Oliveira Trust Dtvm S.A' },
        { id: '079', name: 'Banco Original do Agronegócio S.A.' },
        { id: '212', name: 'Banco Original S.A.' },
        { id: '712', name: 'Banco Ourinvest S.A.' },
        { id: '623', name: 'Banco PAN S.A.' },
        { id: '611', name: 'Banco Paulista S.A.' },
        { id: '643', name: 'Banco Pine S.A.' },
        { id: '658', name: 'Banco Porto Real de Investimentos S.A.' },
        { id: '747', name: 'Banco Rabobank International Brasil S.A.' },
        { id: '633', name: 'Banco Rendimento S.A.' },
        { id: '741', name: 'Banco Ribeirão Preto S.A.' },
        { id: '120', name: 'Banco Rodobens S.A.' },
        { id: '422', name: 'Banco Safra S.A.' },
        { id: '033', name: 'Banco Santander (Brasil) S.A.' },
        { id: '743', name: 'Banco Semear S.A.' },
        { id: '754', name: 'Banco Sistema S.A.' },
        { id: '630', name: 'Banco Smartbank S.A.' },
        { id: '366', name: 'Banco Société Générale Brasil S.A.' },
        { id: '637', name: 'Banco Sofisa S.A.' },
        { id: '464', name: 'Banco Sumitomo Mitsui Brasileiro S.A.' },
        { id: '082', name: 'Banco Topázio S.A.' },
        { id: '634', name: 'Banco Triângulo S.A.' },
        { id: '018', name: 'Banco Tricury S.A.' },
        { id: '655', name: 'Banco Votorantim S.A.' },
        { id: '610', name: 'Banco VR S.A.' },
        { id: '119', name: 'Banco Western Union do Brasil S.A.' },
        { id: '124', name: 'Banco Woori Bank do Brasil S.A.' },
        { id: '348', name: 'Banco Xp S/A' },
        { id: '081', name: 'BancoSeguro S.A.' },
        { id: '021', name: 'BANESTES S.A. Banco do Estado do Espírito Santo' },
        { id: '755', name: 'Bank of America Merrill Lynch Banco Múltiplo S.A.' },
        { id: '268', name: 'Barigui Companhia Hipotecária' },
        { id: '250', name: 'BCV – Banco de Crédito e Varejo S.A.' },
        { id: '144', name: 'BEXS Banco de Câmbio S.A.' },
        { id: '253', name: 'Bexs Corretora de Câmbio S/A' },
        { id: '134', name: 'Bgc Liquidez Dtvm Ltda' },
        { id: '017', name: 'BNY Mellon Banco S.A.' },
        { id: '301', name: 'Bpp Instituição De Pagamentos S.A' },
        { id: '126', name: 'BR Partners Banco de Investimento S.A.' },
        { id: '070', name: 'BRB – Banco de Brasília S.A.' },
        { id: '092', name: 'Brickell S.A. Crédito, Financiamento e Investimento' },
        { id: '173', name: 'BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.' },
        { id: '142', name: 'Broker Brasil Cc Ltda' },
        { id: '292', name: 'BS2 Distribuidora de Títulos e Valores Mobiliários S.A.' },
        { id: '011', name: 'C.Suisse Hedging-Griffo Cv S.A (Credit Suisse)' },
        { id: '104', name: 'Caixa Econômica Federal' },
        { id: '288', name: 'Carol Distribuidora de Títulos e Valor Mobiliários Ltda' },
        { id: '130', name: 'Caruana Scfi' },
        { id: '159', name: 'Casa Credito S.A' },
        { id: '016', name: 'Ccm Desp Trâns Sc E Rs' },
        { id: '089', name: 'Ccr Reg Mogiana' },
        { id: '114', name: 'Central Cooperativa De Crédito No Estado Do Espírito Santo' },
        { id: '114-7', name: 'Central das Cooperativas de Economia e Crédito Mútuo doEstado do Espírito Santo Ltda.' },
        { id: '320', name: 'China Construction Bank (Brasil) Banco Múltiplo S.A.' },
        { id: '477', name: 'Citibank N.A.' },
        { id: '180', name: 'Cm Capital Markets Cctvm Ltda' },
        { id: '127', name: 'Codepe Cvc S.A' },
        { id: '163', name: 'Commerzbank Brasil S.A. – Banco Múltiplo' },
        { id: '060', name: 'Confidence Cc S.A' },
        { id: '085', name: 'Coop Central Ailos' },
        { id: '097', name: 'Cooperativa Central de Crédito Noroeste Brasileiro Ltda.' },
        { id: '085-x', name: 'Cooperativa Central de Crédito Urbano-CECRED' },
        { id: '090-2', name: 'Cooperativa Central de Economia e Crédito Mutuo – SICOOB UNIMAIS' },
        { id: '087-6', name: 'Cooperativa Central de Economia e Crédito Mútuo das Unicredsde Santa Catarina e Paraná' },
        { id: '089-2', name: 'Cooperativa de Crédito Rural da Região da Mogiana' },
        { id: '286', name: 'Cooperativa de Crédito Rural De Ouro' },
        { id: '279', name: 'Cooperativa de Crédito Rural de Primavera Do Leste' },
        { id: '273', name: 'Cooperativa de Crédito Rural de São Miguel do Oeste – Sulcredi/São Miguel' },
        { id: '098', name: 'Credialiança Ccr' },
        { id: '098-1', name: 'CREDIALIANÇA COOPERATIVA DE CRÉDITO RURAL' },
        { id: '010', name: 'Credicoamo' },
        { id: '133', name: 'Cresol Confederação' },
        { id: '182', name: 'Dacasa Financeira S/A' },
        { id: '707', name: 'Banco Daycoval S.A.' },
        { id: '487', name: 'Deutsche Bank S.A. – Banco Alemão' },
        { id: '140', name: 'Easynvest – Título Cv S.A' },
        { id: '149', name: 'Facta S.A. Cfi' },
        { id: '285', name: 'Frente Corretora de Câmbio Ltda.' },
        { id: '278', name: 'Genial Investimentos Corretora de Valores Mobiliários S.A.' },
        { id: '138', name: 'Get Money Cc Ltda' },
        { id: '064', name: 'Goldman Sachs do Brasil Banco Múltiplo S.A.' },
        { id: '177', name: 'Guide Investimentos S.A. Corretora de Valores' },
        { id: '146', name: 'Guitta Corretora de Câmbio Ltda' },
        { id: '078', name: 'Haitong Banco de Investimento do Brasil S.A.' },
        { id: '062', name: 'Hipercard Banco Múltiplo S.A.' },
        { id: '189', name: 'HS Financeira S/A Crédito, Financiamento e Investimentos' },
        { id: '269', name: 'HSBC Brasil S.A. – Banco de Investimento' },
        { id: '271', name: 'IB Corretora de Câmbio, Títulos e Valores Mobiliários S.A.' },
        { id: '157', name: 'Icap Do Brasil Ctvm Ltda' },
        { id: '132', name: 'ICBC do Brasil Banco Múltiplo S.A.' },
        { id: '492', name: 'ING Bank N.V.' },
        { id: '139', name: 'Intesa Sanpaolo Brasil S.A. – Banco Múltiplo' },
        { id: '652', name: 'Itaú Unibanco Holding S.A.' },
        { id: '341', name: 'Itaú Unibanco S.A.' },
        { id: '488', name: 'JPMorgan Chase Bank, National Association' },
        { id: '399', name: 'Kirton Bank S.A. – Banco Múltiplo' },
        { id: '293', name: 'Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda.' },
        { id: '105', name: 'Lecca Crédito, Financiamento e Investimento S/A' },
        { id: '145', name: 'Levycam Ccv Ltda' },
        { id: '113', name: 'Magliano S.A' },
        { id: '323', name: 'Mercado Pago – Conta Do Mercado Livre' },
        { id: '128', name: 'MS Bank S.A. Banco de Câmbio' },
        { id: '137', name: 'Multimoney Cc Ltda' },
        { id: '014', name: 'Natixis Brasil S.A. Banco Múltiplo' },
        { id: '191', name: 'Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.' },
        { id: '753', name: 'Novo Banco Continental S.A. – Banco Múltiplo' },
        { id: '260', name: 'Nu Pagamentos S.A (Nubank)' },
        { id: '613', name: 'Omni Banco S.A.' },
        { id: '290', name: 'Pagseguro Internet S.A' },
        { id: '254', name: 'Paraná Banco S.A.' },
        { id: '326', name: 'Parati – Crédito Financiamento e Investimento S.A.' },
        { id: '194', name: 'Parmetal Distribuidora de Títulos e Valores Mobiliários Ltda' },
        { id: '174', name: 'Pernambucanas Financ S.A' },
        { id: '100', name: 'Planner Corretora De Valores S.A' },
        { id: '125', name: 'Plural S.A. – Banco Múltiplo' },
        { id: '093', name: 'Pólocred Scmepp Ltda' },
        { id: '108', name: 'Portocred S.A' },
        { id: '283', name: 'Rb Capital Investimentos Dtvm Ltda' },
        { id: '101', name: 'Renascenca Dtvm Ltda' },
        { id: '270', name: 'Sagitur Corretora de Câmbio Ltda.' },
        { id: '751', name: 'Scotiabank Brasil S.A. Banco Múltiplo' },
        { id: '276', name: 'Senff S.A. – Crédito, Financiamento e Investimento' },
        { id: '545', name: 'Senso Ccvm S.A' },
        { id: '190', name: 'Servicoop' },
        { id: '183', name: 'Socred S.A' },
        { id: '299', name: 'Sorocred Crédito, Financiamento e Investimento S.A.' },
        { id: '118', name: 'Standard Chartered Bank (Brasil) S/A–Bco Invest.' },
        { id: '197', name: 'Stone Pagamentos S.A' },
        { id: '340', name: 'Super Pagamentos e Administração de Meios Eletrônicos S.A.' },
        { id: '095', name: 'Travelex Banco de Câmbio S.A.' },
        { id: '143', name: 'Treviso Corretora de Câmbio S.A.' },
        { id: '131', name: 'Tullett Prebon Brasil Cvc Ltda' },
        { id: '129', name: 'UBS Brasil Banco de Investimento S.A.' },
        { id: '091-4', name: 'Unicred Central do Rio Grande do Sul' },
        { id: '91', name: 'Unicred Central Rs' },
        { id: '136', name: 'Unicred Cooperativa' },
        { id: '099', name: 'UNIPRIME Central – Central Interestadual de Cooperativas de Crédito Ltda.' },
        { id: '084', name: 'Uniprime Norte do Paraná – Coop de Economia eCrédito Mútuo dos Médicos, Profissionais das Ciências' },
        { id: '298', name: 'Vips Cc Ltda' },
        { id: '310', name: 'Vortx Distribuidora de Títulos e Valores Mobiliários Ltda' },
        { id: '102', name: 'Xp Investimentos S.A' },
    ];
    const formik = useFormik({
        initialValues: {
            empregado: '',
            ocupacao: '',
            dataEmpregado: '',
            rendaMensal: '',
            empresa: '',
            cnpj: '',
            cep: '',
            endereco: '',
            numero: '',
            complemento: '',
            bairro: '',
            cidade: '',
            uf: '',
            banco: '',
            agencia: '',
            contaCorrente: '',
            tipoConta: '',
        },
        validationSchema: object({
            empregado: string().required('A seleção de emprego é obrigatória'),
            ocupacao: string().required('Ocupação é obrigatório'),
            rendaMensal: number().required('A renda mensal é obrigatória').positive('A renda deve ser um valor positivo'),
            dataEmpregado: date().when('empregado', {
                is: 'Sim',
                then: (schema) => schema.required('A data de início é obrigatória'),
                otherwise: (schema) => schema.nullable()
            }),
            empresa: string().when('empregado', {
                is: 'Sim',
                then: (schema) => schema.required('O nome da empresa é obrigatório'),
                otherwise: (schema) => schema.nullable()
            }),
            cnpj: string().when('empregado', {
                is: 'Sim',
                then: (schema) => schema.matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, 'CNPJ inválido').required('O CNPJ é obrigatório'),
                otherwise: (schema) => schema.nullable()
            }),
            cep: string().when('empregado', {
                is: 'Sim',
                then: (schema) => schema.matches(/^\d{5}-\d{3}$/, 'CEP inválido').required('O CEP é obrigatório'),
                otherwise: (schema) => schema.nullable()
            }),
            endereco: string().when('empregado', {
                is: 'Sim',
                then: (schema) => schema.required('O endereço é obrigatório'),
                otherwise: (schema) => schema.nullable()
            }),
            numero: string().when('empregado', {
                is: 'Sim',
                then: (schema) => schema.required('O número é obrigatório'),
                otherwise: (schema) => schema.nullable()
            }),
            complemento: string().nullable(),
            bairro: string().when('empregado', {
                is: 'Sim',
                then: (schema) => schema.required('O bairro é obrigatório'),
                otherwise: (schema) => schema.nullable()
            }),
            cidade: string().when('empregado', {
                is: 'Sim',
                then: (schema) => schema.required('A cidade é obrigatória'),
                otherwise: (schema) => schema.nullable()
            }),
            uf: string().when('empregado', {
                is: 'Sim',
                then: (schema) => schema.required('O estado é obrigatório'),
                otherwise: (schema) => schema.nullable()
            }),
            banco: string().required('O nome do banco é obrigatório'),
            agencia: string()
                .required('O número da agência é obrigatório')
                .matches(/^\d{4}$/, 'A agência deve ter 4 dígitos'),
            contaCorrente: string().required('O número da conta corrente é obrigatório'),
            tipoConta: string().required('O tipo da conta é obrigatório')
        }),
        onSubmit: (values, { setSubmitting }) => {
            console.log("Chamou step4")
            const payload = {
                id: submissionId,
                dadosProfissionais: {
                    empregado: values.empregado === 'Sim',
                    ocupacao: values.ocupacao,
                    dataEmpregado: values.empregado === 'Sim' ? values.dataEmpregado : undefined,
                    rendaMensal: parseFloat(values.rendaMensal),
                    empresa: values.empregado === 'Sim' ? values.empresa : undefined,
                    cnpj: values.empregado === 'Sim' ? values.cnpj : undefined,
                    cep: values.cep,
                    endereco: values.endereco,
                    numero: values.numero,
                    complemento: values.complemento,
                    bairro: values.bairro,
                    cidade: values.cidade,
                    uf: values.uf
                },
                dadosBancarios: {
                    banco: values.banco,
                    agencia: values.agencia,
                    conta: values.contaCorrente,
                    tipoConta: values.tipoConta
                }
            };

            axios.post(`${process.env.REACT_APP_API_URL}/precadastro/step4`, payload)
                .then(response => {
                    console.log('Dados enviados com sucesso:', response.data);
                    advanceStep();
                })
                .catch(error => {
                    console.error('Erro ao enviar dados:', error);
                })
                .finally(() => setSubmitting(false));
        }
    });

    const handleAccountFormat = (event) => {
        let value = event.target.value.replace(/\D/g, '');
        if (value.length > 1) {
            value = value.slice(0, -1) + '-' + value.slice(-1);
        }
        formik.setFieldValue('contaCorrente', value);
    };

    return (
        <form className="mx-auto FormDados2" onSubmit={formik.handleSubmit}>
            <h1>CADASTRO</h1>
            <p>Favor informar seus dados abaixo para seguirmos com a solicitação da sua operação.</p>
            <h3 className="mt-4">DADOS PROFISSIONAIS</h3>
            <div className="col-12">
                <div className="row mb-3">
                    <div className="col-lg-6">
                        <input type="text" className="form-control" id="ocupacao" placeholder="Ocupação" {...formik.getFieldProps('ocupacao')} />
                        {formik.touched.ocupacao && formik.errors.ocupacao ? <div className="text-danger">{formik.errors.ocupacao}</div> : null}
                    </div>
                    <div className="col-lg-6">
                        <input
                            type="text"
                            className="form-control"
                            id="rendaMensal"
                            placeholder="Renda Mensal"
                            onChange={handleCurrencyFormat}  // Use a função aqui
                            value={formattedValue}  // Use o valor formatado aqui
                            onBlur={formik.handleBlur}  // Manter o handleBlur do Formik
                        />
                        {formik.touched.rendaMensal && formik.errors.rendaMensal ? <div className="text-danger">{formik.errors.rendaMensal}</div> : null}
                    </div>
                </div>
                <p className="mb-0">Está empregado?</p>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="empregado" id="inlineRadio1" value="Sim" onChange={formik.handleChange} checked={formik.values.empregado === 'Sim'} />
                    <label className="form-check-label" htmlFor="inlineRadio1">SIM</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="empregado" id="inlineRadio2" value="Não" onChange={formik.handleChange} checked={formik.values.empregado === 'Não'} />
                    <label className="form-check-label" htmlFor="inlineRadio2">NÃO</label>
                </div>
                {formik.touched.empregado && formik.errors.empregado ? <div className="text-danger">{formik.errors.empregado}</div> : null}
            </div>
            {formik.values.empregado === 'Sim' && (
                <>
                    <div>
                        <div className="col-4 mx-auto">
                            <label htmlFor="dataEmpregado" className="mt-3">Digite sua Data de Ínicio:</label>
                            <input type="date" className="form-control" id="dataEmpregado" {...formik.getFieldProps('dataEmpregado')} />
                            {formik.touched.dataEmpregado && formik.errors.dataEmpregado ? <div className="text-danger">{formik.errors.dataEmpregado}</div> : null}
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-8">
                            <input type="text" className="form-control" id="empresa" placeholder="Empresa em que trabalha" {...formik.getFieldProps('empresa')} />
                            {formik.touched.empresa && formik.errors.empresa ? <div className="text-danger">{formik.errors.empresa}</div> : null}
                        </div>
                        <div className="col-md-4">
                            <InputMask
                            id="cnpj"
                            mask="99.999.999/9999-99"
                            className="form-control"
                            placeholder="CNPJ"
                            {...formik.getFieldProps('cnpj')}
                        />
                            {formik.touched.cnpj && formik.errors.cnpj ? <div className="text-danger">{formik.errors.cnpj}</div> : null}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <input
                                type="text"
                                className="form-control"
                                id="cep"
                                placeholder="CEP"
                                value={formik.values.cep}
                                onChange={handleCEPFormat} // Use a função aqui
                                onBlur={formik.handleBlur}  // Manter o handleBlur do Formik
                            />
                            {formik.touched.cep && formik.errors.cep ? (
                                <div className="text-danger">{formik.errors.cep}</div>
                            ) : null}
                        </div>

                        <div className="col-md-4">
                            <input type="text" className="form-control" id="endereco" placeholder="Endereço" {...formik.getFieldProps('endereco')} />
                            {formik.touched.endereco && formik.errors.endereco ? <div className="text-danger">{formik.errors.endereco}</div> : null}
                        </div>
                        <div className="col-md-2">
                            <input type="text" className="form-control" id="numero" placeholder="Número" {...formik.getFieldProps('numero')} />
                            {formik.touched.numero && formik.errors.numero ? <div className="text-danger">{formik.errors.numero}</div> : null}
                        </div>
                        <div className="col-md-2">
                            <input type="text" className="form-control" id="complemento" placeholder="Complemento" {...formik.getFieldProps('complemento')} />
                            {formik.touched.complemento && formik.errors.complemento ? <div className="text-danger">{formik.errors.complemento}</div> : null}
                        </div>
                        <div className="col-md-4">
                            <input type="text" className="form-control" id="bairro" placeholder="Bairro" {...formik.getFieldProps('bairro')} />
                            {formik.touched.bairro && formik.errors.bairro ? <div className="text-danger">{formik.errors.bairro}</div> : null}
                        </div>
                        <div className="col-md-4">
                            <input type="text" className="form-control" id="cidade" placeholder="Cidade" {...formik.getFieldProps('cidade')} />
                            {formik.touched.cidade && formik.errors.cidade ? <div className="text-danger">{formik.errors.cidade}</div> : null}
                        </div>
                        <div className="col-md-4">
                            <select
                                className="form-control"
                                onBlur={() => formik.setFieldTouched('uf', true, true)}
                                id="estado"
                                {...formik.getFieldProps('uf')}
                            >
                                <option value="">Selecione estado</option>
                                <option value="AC">Acre</option>
                                <option value="AL">Alagoas</option>
                                <option value="AP">Amapá</option>
                                <option value="AM">Amazonas</option>
                                <option value="BA">Bahia</option>
                                <option value="CE">Ceará</option>
                                <option value="DF">Distrito Federal</option>
                                <option value="ES">Espírito Santo</option>
                                <option value="GO">Goiás</option>
                                <option value="MA">Maranhão</option>
                                <option value="MT">Mato Grosso</option>
                                <option value="MS">Mato Grosso do Sul</option>
                                <option value="MG">Minas Gerais</option>
                                <option value="PA">Pará</option>
                                <option value="PB">Paraíba</option>
                                <option value="PR">Paraná</option>
                                <option value="PE">Pernambuco</option>
                                <option value="PI">Piauí</option>
                                <option value="RJ">Rio de Janeiro</option>
                                <option value="RN">Rio Grande do Norte</option>
                                <option value="RS">Rio Grande do Sul</option>
                                <option value="RO">Rondônia</option>
                                <option value="RR">Roraima</option>
                                <option value="SC">Santa Catarina</option>
                                <option value="SP">São Paulo</option>
                                <option value="SE">Sergipe</option>
                                <option value="TO">Tocantins</option>
                            </select>
                            {formik.touched.uf && formik.errors.uf ? <div className="text-danger">{formik.errors.uf}</div> : null}
                        </div>
                    </div>
                </>
            )}

            <h3 className="mt-4">DADOS BANCÁRIOS</h3>
            <div className="row">
                <div className="col-md-6">
                    <select className="form-control" id="banco" {...formik.getFieldProps('banco')}>
                        <option value="">Selecione um banco</option>
                        {banks.map((bank) => (
                            <option key={bank.id} value={bank.id}>
                                {bank.name}
                            </option>
                        ))}
                    </select>
                    {formik.touched.banco && formik.errors.banco ? (
                        <div className="text-danger">{formik.errors.banco}</div>
                    ) : null}
                </div>
                <div className="col-md-6">
                    <input
                        type="text"
                        className="form-control"
                        id="agencia"
                        placeholder="Agência"
                        {...formik.getFieldProps('agencia')}
                        onChange={e => {
                            const { value } = e.target;
                            if (/^\d*$/.test(value) && value.length <= 4) {
                                formik.setFieldValue('agencia', value);
                            }
                        }}
                    />
                    {formik.touched.agencia && formik.errors.agencia ? (
                        <div className="text-danger">{formik.errors.agencia}</div>
                    ) : null}
                </div>
                <div className="col-md-6">
                    <input
                        type="text"
                        className="form-control"
                        id="contaCorrente"
                        placeholder="Conta"
                        {...formik.getFieldProps('contaCorrente')}
                        onChange={handleAccountFormat}
                    />
                    {formik.touched.contaCorrente && formik.errors.contaCorrente ? (
                        <div className="text-danger">{formik.errors.contaCorrente}</div>
                    ) : null}
                </div>
                <div className="col-md-6">
                    <select className="form-control" id="tipoConta" {...formik.getFieldProps('tipoConta')}>
                        <option value="">Selecione o tipo de conta</option>
                        <option value="1">CONTA CORRENTE</option>
                        <option value="2">CONTA POUPANÇA</option>
                    </select>
                    {formik.touched.tipoConta && formik.errors.tipoConta ? (
                        <div className="text-danger">{formik.errors.tipoConta}</div>
                    ) : null}
                </div>
            </div>
            <div className="col-md-12 col-lg-12 mt-4">
                <button type="submit" className="btn btn-primary">Avançar</button>
            </div>
        </form>
    );
}

export default Step4; 
