import React, { useState, useRef } from 'react';
import { useFormik } from 'formik';
import { object, string, number, boolean, mixed } from 'yup';
import axios from 'axios';
import InputMask from 'react-input-mask';

function Step5Condominio({ advanceStep, submissionId }) {
    const fileRef1 = useRef(null);
    const fileRef2 = useRef(null);
    const fileRef3 = useRef(null);

    const [formattedRenda, setFormattedRenda] = useState('');

    const handleCurrencyFormat = (event) => {
        let value = event.target.value.replace(/\D/g, '');
        value = value ? Number(value) / 100 : '';
        const formatted = value ? new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2
        }).format(value) : '';
        setFormattedRenda(formatted);
        formik.setFieldValue('rendaMensal', value.toString());
    };

    const handleFileChange = (event, field) => {
        const file = event.currentTarget.files[0];
        formik.setFieldValue(field, file);
        formik.setTouched({ ...formik.touched, [field]: true }, false);
    };

    const formik = useFormik({
        initialValues: {
            nomeSindico: '',
            cpf: '',
            rg: '',
            dataNascimento: '',
            localNascimento: '',
            estadoCivil: '',
            rendaMensal: '',
            cep: '',
            logradouro: '',
            numero: '',
            complemento: '',
            bairro: '',
            municipio: '',
            estado: '',
            telefoneFixo: '',
            telefoneCelular: '',
            email: '',
            sindicoDesde: '',
            possuiImovel: '',
            numeroImoveis: '',
            pessoaPoliticamenteExposta: false,
            identidadeFrente: null,
            identidadeVerso: null,
            comprovanteEndereco: null
        },
        validationSchema: object({
            nomeSindico: string().required('O nome do síndico é obrigatório'),
            cpf: string().required('O CPF é obrigatório').matches(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/, 'CPF inválido'),
            rg: string().required('O RG é obrigatório'),
            dataNascimento: string().required('A data de nascimento é obrigatória').matches(/^\d{2}\/\d{2}\/\d{4}$/, 'Data inválida'),
            localNascimento: string().required('O local de nascimento é obrigatório'),
            estadoCivil: string().required('O estado civil é obrigatório'),
            rendaMensal: number().required('A renda mensal aproximada é obrigatória'),
            cep: string().required('O CEP é obrigatório').matches(/^\d{5}-\d{3}$/, 'CEP inválido'),
            logradouro: string().required('O logradouro é obrigatório'),
            numero: string().required('O número é obrigatório'),
            complemento: string(),
            bairro: string().required('O bairro é obrigatório'),
            municipio: string().required('O município é obrigatório'),
            estado: string().required('O estado é obrigatório'),
            telefoneFixo: string().required('O telefone fixo é obrigatório').matches(/^\(\d{2}\)\s\d{4}-\d{4}$/, 'Telefone fixo inválido'),
            telefoneCelular: string().required('O telefone celular é obrigatório').matches(/^\(\d{2}\)\s\d{5}-\d{4}$/, 'Telefone celular inválido'),
            email: string().required('O e-mail é obrigatório').email('E-mail inválido'),
            sindicoDesde: string().required('A data desde quando é síndico é obrigatória').matches(/^\d{2}\/\d{2}\/\d{4}$/, 'Data inválida'),
            possuiImovel: boolean().required('Informar se possui imóvel é obrigatório'),
            numeroImoveis: number().when('possuiImovel', {
                is: true,
                then: (schema) => schema.required('O número de imóveis é obrigatório').min(1, 'Deve ser pelo menos 1'),
                otherwise: (schema) => schema.nullable()
            }),
            pessoaPoliticamenteExposta: boolean().required('Informar se é pessoa politicamente exposta é obrigatório'),
            identidadeFrente: mixed().required('O documento de identidade frente é obrigatório')
                .test('fileType', 'Somente imagens são permitidas', (value) => {
                    return value && (value.type === 'image/jpeg' || value.type === 'image/png');
                }),
            identidadeVerso: mixed().required('O documento de identidade verso é obrigatório')
                .test('fileType', 'Somente imagens são permitidas', (value) => {
                    return value && (value.type === 'image/jpeg' || value.type === 'image/png');
                }),
            comprovanteEndereco: mixed().required('O comprovante de endereço é obrigatório')
                .test('fileType', 'Somente imagens são permitidas', (value) => {
                    return value && (value.type === 'image/jpeg' || value.type === 'image/png');
                })
        }),
        onSubmit: values => {
            const formData = new FormData();
            formData.append('id', submissionId);
            Object.keys(values).forEach(key => {
                if (key === 'identidadeFrente' || key === 'identidadeVerso' || key === 'comprovanteEndereco') {
                    formData.append(key, values[key]);
                } else {
                    formData.append(key, values[key]);
                }
            });

            axios.post(`${process.env.REACT_APP_API_URL}/precadastro/condominio/step5`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    advanceStep();
                })
                .catch(error => {
                    console.error('Erro ao enviar dados:', error);
                });
        }
    });

    return (
        <>
            <h6 className="ParaCondominio mx-auto mb-3">PARA SEU CONDOMÍNIO</h6>
            <h1>SIMULAÇÃO CONTA DE ALUGUEL</h1>
            <p>Preencha os campos a seguir para iniciar a simulação de adiantamento das contas de aluguel. <br /><strong>É rápido e seguro!</strong></p>

            <form className="mx-auto FormDados2 mt-3" onSubmit={formik.handleSubmit}>
                <h3 className="mb-0">DADOS DO SÍNDICO</h3>
                <h4 className="fs-6 mb-2 mt-3">Informações pessoais</h4>
                <div className="row">
                    <div className="col-12">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Nome completo"
                            {...formik.getFieldProps('nomeSindico')}
                        />
                        {formik.touched.nomeSindico && formik.errors.nomeSindico ? (
                            <div className="text-danger">{formik.errors.nomeSindico}</div>
                        ) : null}
                    </div>
                    <div className="col-6">
                        <InputMask
                            mask="999.999.999-99"
                            className="form-control"
                            placeholder="CPF"
                            {...formik.getFieldProps('cpf')}
                        />
                        {formik.touched.cpf && formik.errors.cpf ? (
                            <div className="text-danger">{formik.errors.cpf}</div>
                        ) : null}
                    </div>
                    <div className="col-6">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="RG"
                            {...formik.getFieldProps('rg')}
                        />
                        {formik.touched.rg && formik.errors.rg ? (
                            <div className="text-danger">{formik.errors.rg}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-4 col-lg-4">
                        <InputMask
                            mask="99/99/9999"
                            className="form-control"
                            placeholder="Data de nascimento"
                            {...formik.getFieldProps('dataNascimento')}
                        />
                        {formik.touched.dataNascimento && formik.errors.dataNascimento ? (
                            <div className="text-danger">{formik.errors.dataNascimento}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-8 col-lg-8">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Local de nascimento (Município/UF)"
                            {...formik.getFieldProps('localNascimento')}
                        />
                        {formik.touched.localNascimento && formik.errors.localNascimento ? (
                            <div className="text-danger">{formik.errors.localNascimento}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-md-6 col-lg-6">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Estado Civil"
                            {...formik.getFieldProps('estadoCivil')}
                        />
                        {formik.touched.estadoCivil && formik.errors.estadoCivil ? (
                            <div className="text-danger">{formik.errors.estadoCivil}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-md-6 col-lg-6 mb-2">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Renda mensal aproximada"
                            value={formattedRenda}
                            onChange={handleCurrencyFormat}
                        />
                        {formik.touched.rendaMensal && formik.errors.rendaMensal ? (
                            <div className="text-danger">{formik.errors.rendaMensal}</div>
                        ) : null}
                    </div>
                    <h4 className="fs-6 mb-2 mt-3">Endereço e dados de contato</h4>
                    <div className="col-12 col-md-4 col-lg-4">
                        <InputMask
                            mask="99999-999"
                            className="form-control"
                            placeholder="CEP"
                            {...formik.getFieldProps('cep')}
                        />
                        {formik.touched.cep && formik.errors.cep ? (
                            <div className="text-danger">{formik.errors.cep}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-8 col-lg-8">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Logradouro"
                            {...formik.getFieldProps('logradouro')}
                        />
                        {formik.touched.logradouro && formik.errors.logradouro ? (
                            <div className="text-danger">{formik.errors.logradouro}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-md-4 col-lg-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Número"
                            {...formik.getFieldProps('numero')}
                        />
                        {formik.touched.numero && formik.errors.numero ? (
                            <div className="text-danger">{formik.errors.numero}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-md-4 col-lg-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Complemento"
                            {...formik.getFieldProps('complemento')}
                        />
                        {formik.touched.complemento && formik.errors.complemento ? (
                            <div className="text-danger">{formik.errors.complemento}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-md-4 col-lg-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Bairro"
                            {...formik.getFieldProps('bairro')}
                        />
                        {formik.touched.bairro && formik.errors.bairro ? (
                            <div className="text-danger">{formik.errors.bairro}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-8 col-lg-8">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Município"
                            {...formik.getFieldProps('municipio')}
                        />
                        {formik.touched.municipio && formik.errors.municipio ? (
                            <div className="text-danger">{formik.errors.municipio}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-md-4 col-lg-4 m-0">
                        <select
                            className="form-select"
                            aria-label="Estado"
                            {...formik.getFieldProps('estado')}
                        >
                            <option value="" label="Selecione o Estado" />
                            <option value="AC" label="Acre" />
                            <option value="AL" label="Alagoas" />
                            <option value="AP" label="Amapá" />
                            <option value="AM" label="Amazonas" />
                            <option value="BA" label="Bahia" />
                            <option value="CE" label="Ceará" />
                            <option value="DF" label="Distrito Federal" />
                            <option value="ES" label="Espírito Santo" />
                            <option value="GO" label="Goiás" />
                            <option value="MA" label="Maranhão" />
                            <option value="MT" label="Mato Grosso" />
                            <option value="MS" label="Mato Grosso do Sul" />
                            <option value="MG" label="Minas Gerais" />
                            <option value="PA" label="Pará" />
                            <option value="PB" label="Paraíba" />
                            <option value="PR" label="Paraná" />
                            <option value="PE" label="Pernambuco" />
                            <option value="PI" label="Piauí" />
                            <option value="RJ" label="Rio de Janeiro" />
                            <option value="RN" label="Rio Grande do Norte" />
                            <option value="RS" label="Rio Grande do Sul" />
                            <option value="RO" label="Rondônia" />
                            <option value="RR" label="Roraima" />
                            <option value="SC" label="Santa Catarina" />
                            <option value="SP" label="São Paulo" />
                            <option value="SE" label="Sergipe" />
                            <option value="TO" label="Tocantins" />
                        </select>
                        {formik.touched.estado && formik.errors.estado ? (
                            <div className="text-danger">{formik.errors.estado}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-md-4 col-lg-4">
                        <InputMask
                            mask="(99) 9999-9999"
                            className="form-control"
                            placeholder="Telefone Fixo"
                            {...formik.getFieldProps('telefoneFixo')}
                        />
                        {formik.touched.telefoneFixo && formik.errors.telefoneFixo ? (
                            <div className="text-danger">{formik.errors.telefoneFixo}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-md-4 col-lg-4">
                        <InputMask
                            mask="(99) 99999-9999"
                            className="form-control"
                            placeholder="Telefone Celular"
                            {...formik.getFieldProps('telefoneCelular')}
                        />
                        {formik.touched.telefoneCelular && formik.errors.telefoneCelular ? (
                            <div className="text-danger">{formik.errors.telefoneCelular}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-md-4 col-lg-4 mb-2">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="E-mail"
                            {...formik.getFieldProps('email')}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="text-danger">{formik.errors.email}</div>
                        ) : null}
                    </div>
                    <h4 className="fs-6 mb-2 mt-3">Outras Informações</h4>
                    <div className="col-12 col-md-6 col-lg-6 mx-auto">
                    <p className="mb-0 mt-2 fs-10">Síndico desde</p>
                        <InputMask
                            mask="99/99/9999"
                            className="form-control"
                            placeholder="Síndico desde (data)"
                            {...formik.getFieldProps('sindicoDesde')}
                        />
                        {formik.touched.sindicoDesde && formik.errors.sindicoDesde ? (
                            <div className="text-danger">{formik.errors.sindicoDesde}</div>
                        ) : null}
                    </div>
                    <div className="col-auto mx-auto mt-3">
                        <p className="mb-0 mt-2 fs-10">Possui imóvel no condomínio? Se sim, quantas unidades?</p>
                        <div className="col-12">
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="possuiImovel"
                                    value="true"
                                    checked={formik.values.possuiImovel === true}
                                    onChange={() => formik.setFieldValue('possuiImovel', true)}
                                />
                                <label className="form-check-label" htmlFor="inlineRadio1">Sim</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="possuiImovel"
                                    value="false"
                                    checked={formik.values.possuiImovel === false}
                                    onChange={() => formik.setFieldValue('possuiImovel', false)}
                                />
                                <label className="form-check-label" htmlFor="inlineRadio2">Não</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 mx-auto">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Número de imóveis"
                            disabled={!formik.values.possuiImovel}
                            {...formik.getFieldProps('numeroImoveis')}
                        />
                        {formik.touched.numeroImoveis && formik.errors.numeroImoveis ? (
                            <div className="text-danger">{formik.errors.numeroImoveis}</div>
                        ) : null}
                    </div>
                    <div className="col-12 mx-auto mt-2 mb-3">
                        <p className="mb-0">Pessoa Politicamente Exposta</p>
                        <div className="col-12">
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="pessoaPoliticamenteExposta"
                                    value="true"
                                    checked={formik.values.pessoaPoliticamenteExposta === true}
                                    onChange={() => formik.setFieldValue('pessoaPoliticamenteExposta', true)}
                                />
                                <label className="form-check-label" htmlFor="inlineRadio1">Sim</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="pessoaPoliticamenteExposta"
                                    value="false"
                                    checked={formik.values.pessoaPoliticamenteExposta === false}
                                    onChange={() => formik.setFieldValue('pessoaPoliticamenteExposta', false)}
                                />
                                <label className="form-check-label" htmlFor="inlineRadio2">Não</label>
                            </div>
                            <textarea class="form-control mb-3" id="exampleFormControlTextarea1" rows="2" placeholder='Descreva em poucas palavras'></textarea>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                        <label htmlFor="identidadeFrente" className="form-label"><strong>Identidade frente</strong></label>
                        <input
                            className="form-control mt-0"
                            type="file"
                            accept="image/jpeg,image/png"
                            id="identidadeFrente"
                            ref={fileRef1}
                            onChange={(event) => handleFileChange(event, 'identidadeFrente')}
                        />
                        {formik.touched.identidadeFrente && formik.errors.identidadeFrente ? (
                            <div className="text-danger">{formik.errors.identidadeFrente}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 my-2 my-md-auto my-lg-auto">
                        <label htmlFor="identidadeVerso" className="form-label"><strong>Identidade verso</strong></label>
                        <input
                            className="form-control mt-0"
                            type="file"
                            accept="image/jpeg,image/png"
                            id="identidadeVerso"
                            ref={fileRef2}
                            onChange={(event) => handleFileChange(event, 'identidadeVerso')}
                        />
                        {formik.touched.identidadeVerso && formik.errors.identidadeVerso ? (
                            <div className="text-danger">{formik.errors.identidadeVerso}</div>
                        ) : null}
                    </div>
                    <div className="col-12 mt-md-3 mt-lg-3">
                        <label htmlFor="comprovanteEndereco" className="form-label"><strong>Comprovante de endereço</strong></label>
                        <input
                            className="form-control mt-0"
                            type="file"
                            id="comprovanteEndereco"
                            accept="image/jpeg,image/png"
                            ref={fileRef3}
                            onChange={(event) => handleFileChange(event, 'comprovanteEndereco')}
                        />
                        {formik.touched.comprovanteEndereco && formik.errors.comprovanteEndereco ? (
                            <div className="text-danger">{formik.errors.comprovanteEndereco}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-md-12 col-lg-12 mt-4">
                        <button type="submit" className="btn btn-primary">avançar</button>
                    </div>
                </div>
            </form>
        </>
    );
}

export default Step5Condominio;
