import React, { useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

function Step6Condominio({ advanceStep, submissionId }) {
    const fileRef1 = useRef(null);
    const fileRef2 = useRef(null);
    const fileRef3 = useRef(null);

    const formik = useFormik({
        initialValues: {
            contaDeLuz: null,
            balancetes: null,
            ataEleicao: null,
        },
        validationSchema: Yup.object({
            contaDeLuz: Yup.mixed()
                .required('A conta de aluguel mais atual é obrigatória')
                .test('fileType', 'Somente arquivos PDF, JPG e PNG são permitidos', (value) => {
                    return value && (value.type === 'image/jpeg' || value.type === 'image/png');
                }),
            balancetes: Yup.mixed()
                .required('Os 3 últimos balancetes do condomínio são obrigatórios')
                .test('fileType', 'Somente arquivos PDF, JPG e PNG são permitidos', (value) => {
                    return value && (value.type === 'image/jpeg' || value.type === 'image/png');
                }),
            ataEleicao: Yup.mixed()
                .required('A ata registrada de assembleia de eleição de síndico e conselheiros é obrigatória')
                .test('fileType', 'Somente arquivos PDF, JPG e PNG são permitidos', (value) => {
                    return value && (value.type === 'image/jpeg' || value.type === 'image/png');
                }),
        }),
        onSubmit: (values) => {
            const formData = new FormData();
            formData.append('id', submissionId);
            formData.append('contaDeLuz', values.contaDeLuz);
            formData.append('balancetes', values.balancetes);
            formData.append('ataEleicao', values.ataEleicao);

            axios.post(`${process.env.REACT_APP_API_URL}/precadastro/condominio/step6`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    advanceStep();
                })
                .catch(error => {
                    console.error('Erro ao enviar dados:', error);
                });
        }
    });

    const handleFileChange = (event, field) => {
        const file = event.currentTarget.files[0];
        formik.setFieldValue(field, file);
        formik.setTouched({ ...formik.touched, [field]: true }, false);
    };

    return (
        <>
            <h6 className="ParaCondominio mx-auto">PARA SEU CONDOMÍNIO</h6>
            <h1>SIMULAÇÃO CONTA DE ALUGUEL</h1>
            <p>Preencha os campos a seguir para iniciar a simulação de adiantamento das contas de aluguel. <br /><strong>É rápido e seguro!</strong></p>

            <form className="mx-auto FormDados2 row mb-3" onSubmit={formik.handleSubmit}>
                <h3 className="mt-4 mb-0">UPLOAD DE ARQUIVOS</h3>
                <div className="col-md-auto col-md-12 col-lg-12 mt-3">
                    <label htmlFor="contaDeLuz" className="form-label"><strong>CONTA DE ALUGUEL MAIS ATUAL (MÁX 3 MESES)</strong></label>
                    <input
                        className="form-control mt-0"
                        type="file"
                        accept="image/jpeg,image/png"
                        id="contaDeLuz"
                        ref={fileRef1}
                        onChange={(event) => handleFileChange(event, 'contaDeLuz')}
                    />
                    {formik.touched.contaDeLuz && formik.errors.contaDeLuz ? (
                        <div className="text-danger">{formik.errors.contaDeLuz}</div>
                    ) : null}
                </div>
                <div className="col-md-auto col-md-12 col-lg-12 mt-3">
                    <label htmlFor="balancetes" className="form-label"><strong>3 ÚLTIMOS BALANCETES DO CONDOMÍNIO COM AS RECEITAS E DESPESAS</strong></label>
                    <input
                        className="form-control mt-0"
                        type="file"
                        accept="image/jpeg,image/png"
                        id="balancetes"
                        ref={fileRef2}
                        onChange={(event) => handleFileChange(event, 'balancetes')}
                    />
                    {formik.touched.balancetes && formik.errors.balancetes ? (
                        <div className="text-danger">{formik.errors.balancetes}</div>
                    ) : null}
                </div>
                <div className="col-md-auto col-md-12 col-lg-12 mt-3">
                    <label htmlFor="ataEleicao" className="form-label"><strong>ATA REGISTRADA DE ASSEMBLEIA DE ELEIÇÃO DE SÍNDICO E CONSELHEIROS</strong></label>
                    <input
                        className="form-control mt-0"
                        type="file"
                        accept="image/jpeg,image/png"
                        id="ataEleicao"
                        ref={fileRef3}
                        onChange={(event) => handleFileChange(event, 'ataEleicao')}
                    />
                    {formik.touched.ataEleicao && formik.errors.ataEleicao ? (
                        <div className="text-danger">{formik.errors.ataEleicao}</div>
                    ) : null}
                </div>

                <div className="col-md-auto col-md-12 col-lg-12 mt-5">
                    <button type="submit" className="btn btn-primary">Avançar</button>
                </div>
            </form>
        </>
    );
}

export default Step6Condominio;
