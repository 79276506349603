import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

function Step2Empresa({ advanceStep, submissionId, setFinalValue, parceiroId, setSimulationId}) {
    const [formattedValue, setFormattedValue] = useState('');
    const [showOptions, setShowOptions] = useState(false);
    const [paymentOptions, setPaymentOptions] = useState({ sixMonths: '', twelveMonths: '' });
    const [errors, setErrors] = useState({ termoDeUso: false, compartilhamentoDados: false, termoFidelidade: false });

    const formik = useFormik({
        initialValues: {
            cep: '',
            endereco: '',
            numero: '',
            complemento: '',
            bairro: '',
            cidade: '',
            uf: '',
            valorMedio: '',
            termoDeUso: false,
            compartilhamentoDados: false,
            termoFidelidade: false
        },
        validationSchema: Yup.object({
            cep: Yup.string().matches(/^\d{5}-\d{3}$/, 'CEP inválido').required('O CEP é obrigatório'),
            endereco: Yup.string().required('O endereço é obrigatório'),
            numero: Yup.string().required('O número é obrigatório'),
            complemento:  Yup.string().nullable(), 
            bairro: Yup.string().required('O bairro é obrigatório'),
            cidade: Yup.string().required('A cidade é obrigatória'),
            uf: Yup.string().required('O estado é obrigatório'),
            valorMedio: Yup.number()
                .required("O valor da conta de aluguel é obrigatório")
                .positive("O valor deve ser positivo")
                .min(800, "O valor mínimo é de R$ 800,00")
        }),
        onSubmit: values => {
            const payload = {
                parceiroId: parceiroId,
                cep: values.cep,
                endereco: values.endereco,
                numero: values.numero,
                complemento: values.complemento,
                bairro: values.bairro,
                cidade: values.cidade,
                uf: values.uf,
                valorMedio: parseFloat(values.valorMedio),
                id: submissionId
            };

            axios.post(`${process.env.REACT_APP_API_URL}/precadastro/pj/step2`, payload)
                .then(response => {
                    setPaymentOptions({
                        sixMonths: formatCurrency(response.data.valorFinal6x),
                        twelveMonths: formatCurrency(response.data.valorFinal12x)
                    });
                    setShowOptions(true);
                    
                })
                .catch(error => console.error('Erro ao enviar dados:', error));
        }
    });

    const handleCEPFormat = (event) => {
        let value = event.target.value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
        if (value.length > 5) {
            value = value.slice(0, 5) + '-' + value.slice(5, 8); // Formata como 00000-000
        }
        formik.setFieldValue('cep', value); // Atualiza o valor no estado do Formik
    };
    
    const handleCurrencyFormat = event => {
        let value = event.target.value.replace(/\D/g, '');
        value = value ? Number(value) / 100 : '';
        const formatted = value ? new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2
        }).format(value) : '';
        setFormattedValue(formatted);
        formik.setFieldValue('valorMedio', value.toString());
    };

    const formatCurrency = value => {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2
        }).format(value);
    };

    const selectOption = (option) => {
        const allTermsAccepted = formik.values.termoDeUso && formik.values.compartilhamentoDados;
        if (!allTermsAccepted) {
            setErrors({
                termoDeUso: !formik.values.termoDeUso,
                compartilhamentoDados: !formik.values.compartilhamentoDados
            });
            return; // Prevent further execution if terms are not accepted
        }

        const parcelamento = option === '6' ? '6' : '12';
        const aceite = {
            termoDeUso: formik.values.termoDeUso,
            compartilhamentoDados: formik.values.compartilhamentoDados,
            termoFidelidade: false
        }
        const payload = {
            parceiroId: parceiroId,
            cep: formik.values.cep,
            endereco: formik.values.endereco,
            numero: formik.values.numero,
            complemento: formik.values.complemento,
            bairro: formik.values.bairro,
            cidade: formik.values.cidade,
            uf: formik.values.uf,
            valorMedio: parseFloat(formik.values.valorMedio),
            id: submissionId,
            parcelamento: parcelamento,
            aceite: aceite
        };

        axios.post(`${process.env.REACT_APP_API_URL}/precadastro/pj/step2`, payload)
            .then(response => {
                setFinalValue(option === '6' ? paymentOptions.sixMonths : paymentOptions.twelveMonths);
                setSimulationId(response.data.id);
                console.log("[2c] response: "+ JSON.stringify(response.data));
                advanceStep();
            })
            .catch(error => console.error('Erro ao enviar dados:', error));
    };

    return (<>
        <h6 class="ParaEmpresa mx-auto mb-3">PARA SUA EMPRESA</h6>
        <h1>SIMULAÇÃO ANTECIPAÇÃO DE ALUGUEL</h1>
        <p>Preencha os campos a seguir para iniciar a simulaçao de adiantamento das contas de aluguel. <br /><strong>É rápido e seguro!</strong></p>
        <form className="mx-auto FormDados" onSubmit={formik.handleSubmit}>
        <div className="row">
                <div className="col-md-4">
                    <input
                        type="text"
                        className="form-control"
                        id="cep"
                        placeholder="CEP"
                        value={formik.values.cep}
                        onChange={handleCEPFormat} // Use a função aqui
                        onBlur={formik.handleBlur}  // Manter o handleBlur do Formik
                    />
                    {formik.touched.cep && formik.errors.cep ? (
                        <div className="text-danger">{formik.errors.cep}</div>
                    ) : null}
                </div>
                <div className="col-md-8">
                            <input type="text" className="form-control" id="endereco" placeholder="Endereço" {...formik.getFieldProps('endereco')} />
                            {formik.touched.endereco && formik.errors.endereco ? <div className="text-danger">{formik.errors.endereco}</div> : null}
                        </div>
                        <div className="col-md-4">
                            <input type="text" className="form-control" id="numero" placeholder="Número" {...formik.getFieldProps('numero')} />
                            {formik.touched.numero && formik.errors.numero ? <div className="text-danger">{formik.errors.numero}</div> : null}
                        </div>
                        <div className="col-md-2">
                            <input type="text" className="form-control" id="complemento" placeholder="Complemento" {...formik.getFieldProps('complemento')} />
                            {formik.touched.complemento && formik.errors.complemento ? <div className="text-danger">{formik.errors.complemento}</div> : null}
                        </div>
                        <div className="col-md-6">
                            <input type="text" className="form-control" id="bairro" placeholder="Bairro" {...formik.getFieldProps('bairro')} />
                            {formik.touched.bairro && formik.errors.bairro ? <div className="text-danger">{formik.errors.bairro}</div> : null}
                        </div>
                        <div className="col-md-6">
                            <input type="text" className="form-control" id="cidade" placeholder="Cidade" {...formik.getFieldProps('cidade')} />
                            {formik.touched.cidade && formik.errors.cidade ? <div className="text-danger">{formik.errors.cidade}</div> : null}
                        </div>
                        <div className="col-md-6">
                            <select
                                className="form-control"
                                onBlur={() => formik.setFieldTouched('uf', true, true)}
                                id="estado"
                                {...formik.getFieldProps('uf')}
                            >
                                <option value="">Selecione estado</option>
                                <option value="AC">Acre</option>
                                <option value="AL">Alagoas</option>
                                <option value="AP">Amapá</option>
                                <option value="AM">Amazonas</option>
                                <option value="BA">Bahia</option>
                                <option value="CE">Ceará</option>
                                <option value="DF">Distrito Federal</option>
                                <option value="ES">Espírito Santo</option>
                                <option value="GO">Goiás</option>
                                <option value="MA">Maranhão</option>
                                <option value="MT">Mato Grosso</option>
                                <option value="MS">Mato Grosso do Sul</option>
                                <option value="MG">Minas Gerais</option>
                                <option value="PA">Pará</option>
                                <option value="PB">Paraíba</option>
                                <option value="PR">Paraná</option>
                                <option value="PE">Pernambuco</option>
                                <option value="PI">Piauí</option>
                                <option value="RJ">Rio de Janeiro</option>
                                <option value="RN">Rio Grande do Norte</option>
                                <option value="RS">Rio Grande do Sul</option>
                                <option value="RO">Rondônia</option>
                                <option value="RR">Roraima</option>
                                <option value="SC">Santa Catarina</option>
                                <option value="SP">São Paulo</option>
                                <option value="SE">Sergipe</option>
                                <option value="TO">Tocantins</option>
                            </select>
                            {formik.touched.uf && formik.errors.uf ? <div className="text-danger">{formik.errors.uf}</div> : null}
                        </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <input
                        type="text"
                        className="form-control"
                        id="valorMedio"
                        placeholder="Valor do Aluguel"
                        value={formattedValue}
                        onChange={handleCurrencyFormat}
                    />
                    {formik.touched.valorMedio && formik.errors.valorMedio ? (
                        <div className="text-danger">{formik.errors.valorMedio}</div>
                    ) : null}
                </div>
                <div className="col-md-12 mt-3">
                    <button type="submit" className="btn btn-secondary">Simular</button>
                </div>

                {showOptions && (
                    <>
                        <div className="col-md-12 mt-5">
                            <div className="btn-group" role="group" aria-label="Basic example">
                                <div className="col-12 col-lg-6 mb-3 mb-lg-auto">
                                    <div className="DetBotaoSimula">
                                        <h3>6 meses</h3>
                                        <button type="button" className="btn btn-primary" onClick={() => selectOption('6')}>QUERO ESSE!</button>
                                        <p>Até <strong>{paymentOptions.sixMonths}</strong> antecipando 6 meses</p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="DetBotaoSimula">
                                        <h3>12 meses</h3>
                                        <button type="button" className="btn btn-primary" onClick={() => selectOption('12')}>QUERO ESSE!</button>
                                        <p>Até <strong>{paymentOptions.twelveMonths}</strong> antecipando 12 meses</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mt-3">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="termoDeUso" {...formik.getFieldProps('termoDeUso')} />
                                <label className="form-check-label" htmlFor="termoDeUso">
                                    Estou de acordo com o <a href="https://emprestacapital.com.br/termo-de-uso-e-privacidade/" target="_blank">Termo de Uso</a>
                                </label>
                                {errors.termoDeUso && <div className="text-danger">Aceitar os termos de uso é obrigatório</div>}
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="compartilhamentoDados" {...formik.getFieldProps('compartilhamentoDados')} />
                                <label className="form-check-label" htmlFor="compartilhamentoDados">
                                    Autorizo o compartilhamento de meus dados entre Administradora e Empresta Capital
                                </label>
                                {errors.compartilhamentoDados && <div className="text-danger">Autorizar o compartilhamento de dados é obrigatório</div>}
                            </div>

                        </div></>
                )}


            </div>
        </form></>
    );
}

export default Step2Empresa;
