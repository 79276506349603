import React from 'react';
import { useFormik } from 'formik';
import { object, string, boolean, date } from 'yup';
import axios from 'axios';

function Step3Empresa({ advanceStep, submissionId, simulationId }) {

    const estados = ["AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RS", "RO", "RR", "SC", "SP", "SE", "TO"];

    console.log("simulationId: "+simulationId);

    const formik = useFormik({
        initialValues: {
            cep: '',
            logradouro: '',
            numero: '',
            complemento: '',
            bairro: '',
            municipio: '',
            estado: '',
            telefoneContato: '',
            segmentoEmpresa: '',
            dataAbertura: '',
            inscricaoEstadual: '',
            isentoEstadual: false,
            inscricaoMunicipal: '',
            isentoMunicipal: false,
            siteCorporativo: '',
            simulationId: ''
        },
        validationSchema: object({
            cep: string()
                .required('O CEP é obrigatório')
                .matches(/^\d{5}-\d{3}$/, 'Formato de CEP inválido (use 00000-000)'),
            logradouro: string().required('O logradouro é obrigatório'),
            numero: string()
                .required('O número é obrigatório')
                .matches(/^\d+$/, 'O número deve conter apenas dígitos numéricos'),
            bairro: string().required('O bairro é obrigatório'),
            municipio: string().required('O município é obrigatório'),
            estado: string().required('O estado é obrigatório'),
            telefoneContato: string()
                .required('O telefone de contato é obrigatório')
                .matches(/^\(\d{2}\) \d{5}-\d{4}$/, 'Formato de telefone inválido (use (xx) xxxxx-xxxx)'),
            segmentoEmpresa: string().required('O segmento da empresa é obrigatório'),
            dataAbertura: date().required('A data de abertura é obrigatória'),
            inscricaoEstadual: string().when('isentoEstadual', {
                is: false,
                then: (schema) => schema.required('A inscrição estadual é obrigatória se não for isento'),
                otherwise: (schema) => schema.nullable()
            }),
            inscricaoMunicipal: string().when('isentoMunicipal', {
                is: false,
                then: (schema) => schema.required('A inscrição municipal é obrigatória se não for isento'),
                otherwise: (schema) => schema.nullable()
            }),
            isentoEstadual: boolean(),
            isentoMunicipal: boolean(),
            siteCorporativo: string().test(
                'is-url-valid',
                'Formato de URL inválido',
                (value) => {
                    if (!value) return true;
                    const urlPattern = new RegExp('^(https?:\\/\\/)?' + // protocolo
                        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domínio e extensão
                        '((\\d{1,3}\\.){3}\\d{1,3}))' + // ou endereço IP (v4)
                        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // porta e caminho
                        '(\\?[;&a-z\\d%_.~+=-]*)?' + // consulta
                        '(\\#[-a-z\\d_]*)?$', 'i'); // fragmento
                    return urlPattern.test(value);
                }
            )
        }),
        onSubmit: values => {
            console.log("===> simulationId"+ simulationId);
            const payload = {
                id: submissionId,
                smId: simulationId,
                ...values
            };

            console.log("payload: "+ JSON.stringify(payload));

            axios.post(`${process.env.REACT_APP_API_URL}/precadastro/pj/step3`, payload)
                .then(response => {
                    advanceStep();
                })
                .catch(error => console.error('Erro ao enviar dados:', error));
        }
    });

    const addHttp = (value) => {
        if (!value) return value;
        if (!/^https?:\/\//i.test(value)) {
            return `http://${value}`;
        }
        return value;
    };
    const formatTelefone = (value) => {
        if (!value) return value;

        const telefone = value.replace(/[^\d]/g, '');

        if (telefone.length <= 2) return telefone;

        if (telefone.length <= 7) {
            return `(${telefone.slice(0, 2)}) ${telefone.slice(2, 7)}`;
        }

        return `(${telefone.slice(0, 2)}) ${telefone.slice(2, 7)}-${telefone.slice(7, 11)}`;
    };

    const formatCep = (value) => {
        if (!value) return value;

        const cep = value.replace(/[^\d]/g, '');

        if (cep.length <= 5) return cep;

        return `${cep.slice(0, 5)}-${cep.slice(5, 8)}`;
    };

    return (
        <>
            <h6 className="ParaEmpresa mx-auto mb-3">PARA SUA EMPRESA</h6>
            <h1>SIMULAÇÃO CONTA DE ALUGUEL</h1>
            <p>Preencha os campos a seguir para iniciar a simulação de adiantamento das contas de aluguel. <br /><strong>É rápido e seguro!</strong></p>

            <form className="mx-auto FormDados2 mt-3" onSubmit={formik.handleSubmit}>
                <h3>DADOS DA EMPRESA</h3>
                <div className="row">
                    <div className="col-md-auto col-md-4 col-lg-4">
                        <input
                            type="text"
                            className="form-control"
                            id="cep"
                            placeholder="CEP"
                            {...formik.getFieldProps('cep')}
                            value={formatCep(formik.values.cep)}
                            onChange={(e) => formik.setFieldValue('cep', formatCep(e.target.value))}
                        />
                        {formik.touched.cep && formik.errors.cep ? (
                            <div className="text-danger">{formik.errors.cep}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-md-12 col-lg-8">
                        <input
                            type="text"
                            className="form-control"
                            id="logradouro"
                            placeholder="Logradouro"
                            {...formik.getFieldProps('logradouro')}
                        />
                        {formik.touched.logradouro && formik.errors.logradouro ? (
                            <div className="text-danger">{formik.errors.logradouro}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-md-4 col-lg-4">
                        <input
                            type="number"
                            className="form-control"
                            id="numero"
                            placeholder="Número"
                            {...formik.getFieldProps('numero')}
                        />
                        {formik.touched.numero && formik.errors.numero ? (
                            <div className="text-danger">{formik.errors.numero}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-md-2 col-lg-2">
                        <input
                            type="text"
                            className="form-control"
                            id="complemento"
                            placeholder="Complemento"
                            {...formik.getFieldProps('complemento')}
                        />
                    </div>
                    <div className="col-md-auto col-md-6 col-lg-6">
                        <input
                            type="text"
                            className="form-control"
                            id="bairro"
                            placeholder="Bairro"
                            {...formik.getFieldProps('bairro')}
                        />
                        {formik.touched.bairro && formik.errors.bairro ? (
                            <div className="text-danger">{formik.errors.bairro}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-lg-4">
                        <input
                            type="text"
                            className="form-control"
                            id="municipio"
                            placeholder="Município"
                            {...formik.getFieldProps('municipio')}
                        />
                        {formik.touched.municipio && formik.errors.municipio ? (
                            <div className="text-danger">{formik.errors.municipio}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-md-4 col-lg-4">
                        <select
                            className="form-control"
                            id="estado"
                            {...formik.getFieldProps('estado')}
                        >
                            <option value="" label="Selecione o estado" />
                            {estados.map((estado) => (
                                <option key={estado} value={estado}>{estado}</option>
                            ))}
                        </select>
                        {formik.touched.estado && formik.errors.estado ? (
                            <div className="text-danger">{formik.errors.estado}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-md-4 col-lg-4">
                        <input
                            mask="(99) 99999-9999"
                            type="text"
                            className="form-control"
                            id="telefoneContato"
                            placeholder="Telefone do Contato"
                            {...formik.getFieldProps('telefoneContato')}
                            value={formatTelefone(formik.values.telefoneContato)}
                            onChange={(e) => formik.setFieldValue('telefoneContato', formatTelefone(e.target.value))}
                        />
                        {formik.touched.telefoneContato && formik.errors.telefoneContato ? (
                            <div className="text-danger">{formik.errors.telefoneContato}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-lg-12">
                        <input
                            type="text"
                            className="form-control"
                            id="segmentoEmpresa"
                            placeholder="Segmento da Empresa"
                            {...formik.getFieldProps('segmentoEmpresa')}
                        />
                        {formik.touched.segmentoEmpresa && formik.errors.segmentoEmpresa ? (
                            <div className="text-danger">{formik.errors.segmentoEmpresa}</div>
                        ) : null}
                    </div>
                    <div className="row mt-2 mb-2">
                        <div className="mx-auto col-6">
                            <label htmlFor="">Data de abertura da empresa:</label>
                            <input
                                type="date"
                                className="form-control"
                                id="dataAbertura"
                                placeholder="Data de abertura"
                                {...formik.getFieldProps('dataAbertura')}
                            />
                            {formik.touched.dataAbertura && formik.errors.dataAbertura ? (
                                <div className="text-danger">{formik.errors.dataAbertura}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-auto col-lg-6">
                        <input
                            type="text"
                            className="form-control"
                            id="inscricaoEstadual"
                            placeholder="Inscrição Estadual"
                            {...formik.getFieldProps('inscricaoEstadual')}
                            disabled={formik.values.isentoEstadual}
                        />
                        {formik.touched.inscricaoEstadual && formik.errors.inscricaoEstadual ? (
                            <div className="text-danger">{formik.errors.inscricaoEstadual}</div>
                        ) : null}
                        <div className="form-check mx-auto">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="isentoEstadual"
                                {...formik.getFieldProps('isentoEstadual')}
                                checked={formik.values.isentoEstadual}
                                onChange={() => {
                                    formik.setFieldValue('isentoEstadual', !formik.values.isentoEstadual);
                                    if (!formik.values.isentoEstadual) {
                                        formik.setFieldValue('inscricaoEstadual', '');
                                    }
                                }}
                            />
                            <label className="form-check-label" htmlFor="isentoEstadual">
                                Isento
                            </label>
                        </div>
                    </div>
                    <div className="col-md-auto col-lg-6">
                        <input
                            type="text"
                            className="form-control"
                            id="inscricaoMunicipal"
                            placeholder="Inscrição Municipal"
                            {...formik.getFieldProps('inscricaoMunicipal')}
                            disabled={formik.values.isentoMunicipal}
                        />
                        {formik.touched.inscricaoMunicipal && formik.errors.inscricaoMunicipal ? (
                            <div className="text-danger">{formik.errors.inscricaoMunicipal}</div>
                        ) : null}
                        <div className="form-check mx-auto">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="isentoMunicipal"
                                {...formik.getFieldProps('isentoMunicipal')}
                                checked={formik.values.isentoMunicipal}
                                onChange={() => {
                                    formik.setFieldValue('isentoMunicipal', !formik.values.isentoMunicipal);
                                    if (!formik.values.isentoMunicipal) {
                                        formik.setFieldValue('inscricaoMunicipal', '');
                                    }
                                }}
                            />
                            <label className="form-check-label" htmlFor="isentoMunicipal">
                                Isento
                            </label>
                        </div>
                    </div>
                    <div className="col-md-auto col-lg-12">
                        <input
                            type="text"
                            className="form-control"
                            id="siteCorporativo"
                            placeholder="Site corporativo"
                            {...formik.getFieldProps('siteCorporativo')}
                            onChange={(e) => formik.setFieldValue('siteCorporativo', addHttp(e.target.value))}
                        />
                        {formik.touched.siteCorporativo && formik.errors.siteCorporativo ? (
                            <div className="text-danger">{formik.errors.siteCorporativo}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-md-12 col-lg-12 mt-4">
                        <button type="submit" className="btn btn-primary">Avançar</button>
                    </div>
                </div>
            </form>
        </>
    );
}

export default Step3Empresa;
