import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import InputMask from 'react-input-mask';

function Step4Condominio({ advanceStep, submissionId }) {
    const formik = useFormik({
        initialValues: {
            banco: '',
            agencia: '',
            conta: '',
            referenciaNome: '',
            referenciaCnpj: '',
            referenciaTelefone: '',
            referenciaNomeContato: ''
        },
        validationSchema: Yup.object({
            banco: Yup.string().required('O banco é obrigatório'),
            agencia: Yup.string().required('A agência é obrigatória'),
            conta: Yup.string().required('A conta é obrigatória'),
            referenciaNome: Yup.string().required('O nome da referência comercial é obrigatório'),
            referenciaCnpj: Yup.string().required('O CNPJ é obrigatório').matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/, 'CNPJ inválido'),
            referenciaTelefone: Yup.string().required('O telefone é obrigatório').matches(/^\(\d{2}\)\s\d{4,5}\-\d{4}$/, 'Telefone inválido'),
            referenciaNomeContato: Yup.string().required('O nome do contato é obrigatório')
        }),
        onSubmit: values => {
            const payload = {
                id: submissionId,
                ...values
            };

            axios.post(`${process.env.REACT_APP_API_URL}/precadastro/condominio/step4`, payload)
                .then(response => {
                    advanceStep();
                })
                .catch(error => {
                    console.error('Erro ao enviar dados:', error);
                });
        }
    });

    return (
        <>
            <h6 className="ParaCondominio mx-auto mb-3">PARA SEU CONDOMÍNIO</h6>
            <h1>SIMULAÇÃO CONTA DE ALUGUEL</h1>
            <p>Preencha os campos a seguir para iniciar a simulação de adiantamento das contas de aluguel. <br /><strong>É rápido e seguro!</strong></p>

            <form className="mx-auto FormDados2 mt-2" onSubmit={formik.handleSubmit}>
                <div className="row mb-0">
                    <h3 className="mt-3 mb-0">DADOS BANCÁRIOS</h3>
                    <div className="col-12 col-md-4 col-lg-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Banco"
                            {...formik.getFieldProps('banco')}
                        />
                        {formik.touched.banco && formik.errors.banco ? (
                            <div className="text-danger">{formik.errors.banco}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-4 col-lg-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Agência"
                            {...formik.getFieldProps('agencia')}
                        />
                        {formik.touched.agencia && formik.errors.agencia ? (
                            <div className="text-danger">{formik.errors.agencia}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-4 col-lg-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Conta"
                            {...formik.getFieldProps('conta')}
                        />
                        {formik.touched.conta && formik.errors.conta ? (
                            <div className="text-danger">{formik.errors.conta}</div>
                        ) : null}
                    </div>

                    <h3 className="mt-4 mb-0">REFERÊNCIA COMERCIAL</h3>
                    <div className="col-12">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Nome"
                            {...formik.getFieldProps('referenciaNome')}
                        />
                        {formik.touched.referenciaNome && formik.errors.referenciaNome ? (
                            <div className="text-danger">{formik.errors.referenciaNome}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-4 col-lg-4">
                        <InputMask
                            mask="99.999.999/9999-99"
                            className="form-control"
                            placeholder="CNPJ"
                            {...formik.getFieldProps('referenciaCnpj')}
                        />
                        {formik.touched.referenciaCnpj && formik.errors.referenciaCnpj ? (
                            <div className="text-danger">{formik.errors.referenciaCnpj}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-4 col-lg-4">
                        <InputMask
                            mask="(99) 99999-9999"
                            className="form-control"
                            placeholder="Telefone"
                            {...formik.getFieldProps('referenciaTelefone')}
                        />
                        {formik.touched.referenciaTelefone && formik.errors.referenciaTelefone ? (
                            <div className="text-danger">{formik.errors.referenciaTelefone}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-4 col-lg-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Nome do contato"
                            {...formik.getFieldProps('referenciaNomeContato')}
                        />
                        {formik.touched.referenciaNomeContato && formik.errors.referenciaNomeContato ? (
                            <div className="text-danger">{formik.errors.referenciaNomeContato}</div>
                        ) : null}
                    </div>

                    <div className="col-md-auto col-md-12 col-lg-12 mt-4">
                        <button type="submit" className="btn btn-primary">Avançar</button>
                    </div>
                </div>
            </form>
        </>
    );
}

export default Step4Condominio;
