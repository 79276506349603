import React, { useState, useRef } from 'react';
import { useFormik } from 'formik';
import { object, string, mixed } from 'yup';
import axios from 'axios';

function Step6Empresa({ advanceStep, submissionId }) {
    const fileRef1 = useRef(null);
    const fileRef2 = useRef(null);
    const fileRef3 = useRef(null);

    const formik = useFormik({
        initialValues: {
            contaDeLuz: null,
            contratoSocial: null,
            declaracaoFaturamento: null
        },
        validationSchema: object({
            contaDeLuz: mixed().required('A conta de aluguel é obrigatória')
                .test('fileType', 'Somente imagens são permitidas', (value) => {
                    return value && (value.type === 'image/jpeg' || value.type === 'image/png' || value.type === 'application/pdf');
                }),
            contratoSocial: mixed().required('O contrato social é obrigatório')
                .test('fileType', 'Somente imagens são permitidas', (value) => {
                    return value && (value.type === 'image/jpeg' || value.type === 'image/png' || value.type === 'application/pdf');
                }),
            declaracaoFaturamento: mixed().required('A declaração de faturamento é obrigatória')
                .test('fileType', 'Somente imagens são permitidas', (value) => {
                    return value && (value.type === 'image/jpeg' || value.type === 'image/png' || value.type === 'application/pdf');
                })
        }),
        onSubmit: async (values) => {
            const formData = new FormData();
            formData.append('id', submissionId);
            formData.append('contaDeLuz', values.contaDeLuz);
            formData.append('contratoSocial', values.contratoSocial);
            formData.append('declaracaoFaturamento', values.declaracaoFaturamento);

            try {
                await axios.post(`${process.env.REACT_APP_API_URL}/precadastro/pj/step6`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                advanceStep();
            } catch (error) {
                console.error('Erro ao enviar dados:', error);
            }
        }
    });

    const handleFileChange = (event, field) => {
        const file = event.currentTarget.files[0];
        formik.setFieldValue(field, file);

        // Clear the touched state of the field to remove validation message
        formik.setTouched({ ...formik.touched, [field]: false });
    };
    return (
        <>
            <h6 className="ParaEmpresa mx-auto">PARA SUA EMPRESA</h6>
            <h1>SIMULAÇÃO CONTA DE ALUGUEL</h1>
            <p>Preencha os campos a seguir para iniciar a simulação de adiantamento das contas de aluguel. <br /><strong>É rápido e seguro!</strong></p>

            <form className="mx-auto FormDados2 row mb-3" onSubmit={formik.handleSubmit}>
                <h3 className="mt-4 mb-0">UPLOAD DE ARQUIVOS</h3>
                <div className="col-md-auto col-md-12 col-lg-12 mt-3">
                    <label htmlFor="contaDeLuz" className="form-label"><strong>CONTA DE ALUGUEL</strong></label>
                    <input
                        className="form-control mt-0"
                        type="file"
                        id="contaDeLuz"
                        accept="image/jpeg, image/png"
                        onChange={(event) => handleFileChange(event, 'contaDeLuz')}
                        ref={fileRef1}
                    />
                    {formik.touched.contaDeLuz && formik.errors.contaDeLuz ? (
                        <div className="text-danger">{formik.errors.contaDeLuz}</div>
                    ) : null}
                </div>
                <div className="col-md-auto col-md-12 col-lg-12 mt-3">
                    <label htmlFor="contratoSocial" className="form-label"><strong>CONTRATO SOCIAL OU ESTATUTO SOCIAL</strong></label>
                    <input
                        className="form-control mt-0"
                        type="file"
                        id="contratoSocial"
                        accept="image/jpeg, image/png"
                        onChange={(event) => handleFileChange(event, 'contratoSocial')}
                        ref={fileRef2}
                    />
                    {formik.touched.contratoSocial && formik.errors.contratoSocial ? (
                        <div className="text-danger">{formik.errors.contratoSocial}</div>
                    ) : null}
                </div>
                <div className="col-md-auto col-md-12 col-lg-12 mt-3">
                    <label htmlFor="declaracaoFaturamento" className="form-label"><strong>DECLARAÇÃO DE FATURAMENTO</strong></label>
                    <input
                        className="form-control mt-0"
                        type="file"
                        id="declaracaoFaturamento"
                        accept="image/jpeg, image/png"
                        onChange={(event) => handleFileChange(event, 'declaracaoFaturamento')}
                        ref={fileRef3}
                    />
                    {formik.touched.declaracaoFaturamento && formik.errors.declaracaoFaturamento ? (
                        <div className="text-danger">{formik.errors.declaracaoFaturamento}</div>
                    ) : null}
                </div>
                <div className="col-md-auto col-md-12 col-lg-12 mt-4">
                    <button type="submit" className="btn btn-primary">Avançar e Finalizar</button>
                </div>
            </form>
        </>
    )
}

export default Step6Empresa;
