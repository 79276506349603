import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import InputMask from 'react-input-mask';
import { cnpj as cnpjValidator } from 'cpf-cnpj-validator';

function Step1Condominio({ advanceStep }) {
    const formik = useFormik({
        initialValues: {
            razaoSocial: '',
            nomeCondominio: '',
            cnpj: '',
            celular: '',
            email: '',
            tipo: 'CONDOMINIO'
        },
        validationSchema: Yup.object({
            razaoSocial: Yup.string().required('A razão social é obrigatória'),
            nomeCondominio: Yup.string().required('O nome do condomínio é obrigatório'),
            cnpj: Yup.string()
                .required('O CNPJ é obrigatório')
                .test('is-valid-cnpj', 'CNPJ inválido', value => cnpjValidator.isValid(value)),
            celular: Yup.string().required('O celular do contato é obrigatório'),
            email: Yup.string().email('E-mail inválido').required('O e-mail é obrigatório')
        }),
        onSubmit: async (values) => {
            await axios.post(`${process.env.REACT_APP_API_URL}/precadastro/condominio/step1`, values).then(response => {
                console.log('Dados enviados com sucesso:', response.data);
                advanceStep(response.data.id);
            })
                .catch(error => {
                    console.error('Erro ao enviar dados:', error);
                });
        }
    });

    return (
        <>
            <h6 className="ParaCondominio mx-auto mb-3">PARA SEU CONDOMÍNIO</h6>
            <h1>SIMULAÇÃO CONTA DE ALUGUEL</h1>
            <p>Preencha os campos a seguir para iniciar a simulação de adiantamento das contas de aluguel. <br /><strong>É rápido e seguro!</strong></p>

            <form className="mx-auto FormDados" onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Razão Social"
                            {...formik.getFieldProps('razaoSocial')}
                        />
                        {formik.touched.razaoSocial && formik.errors.razaoSocial ? (
                            <div className="text-danger">{formik.errors.razaoSocial}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-12 col-lg-12">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Nome do condomínio"
                            {...formik.getFieldProps('nomeCondominio')}
                        />
                        {formik.touched.nomeCondominio && formik.errors.nomeCondominio ? (
                            <div className="text-danger">{formik.errors.nomeCondominio}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                        <InputMask
                            mask="99.999.999/9999-99"
                            className="form-control"
                            placeholder="CNPJ"
                            {...formik.getFieldProps('cnpj')}
                        />
                        {formik.touched.cnpj && formik.errors.cnpj ? (
                            <div className="text-danger">{formik.errors.cnpj}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                        <InputMask
                            mask="(99) 99999-9999"
                            className="form-control"
                            placeholder="Celular do contato"
                            {...formik.getFieldProps('celular')}
                        />
                        {formik.touched.celular && formik.errors.celular ? (
                            <div className="text-danger">{formik.errors.celular}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-12 col-lg-12">
                        <input
                            type="text"
                            className="form-control mt-2"
                            placeholder="E-mail"
                            {...formik.getFieldProps('email')}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="text-danger">{formik.errors.email}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-12 col-lg-12 mt-3 w-md-100">
                        <button type="submit" className="btn btn-primary">AVANÇAR</button>
                    </div>
                </div>
            </form>
        </>
    );
}

export default Step1Condominio;