import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from "axios";
import InputMask from 'react-input-mask';
import { cnpj as cnpjValidator } from 'cpf-cnpj-validator';

function Step1Empresa({ advanceStep }) {
    const validationSchema = Yup.object({
        nome: Yup.string().required("O nome da empresa é obrigatório"),
        nomeFantasia: Yup.string().required("Nome fantasia é obrigatório"),
        cnpj: Yup.string().required("CNPJ é obrigatório").test('is-cnpj-valid', 'CNPJ inválido', value => cnpjValidator.isValid(value)),
        celular: Yup.string().required("Celular de contato é obrigatório")
            .matches(/^\(\d{2}\)\s(\d{4,5}-\d{4})$/, "Celular inválido"),
        email: Yup.string().email("Email inválido").required("Email obrigatório"),
    });

    const formik = useFormik({
        initialValues: {
            nome: '',
            nomeFantasia: '',
            cnpj: '',
            celular: '',
            email: '',
            tipo: 'Empresa'
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log('Submitting values:', values);
            const url = `${process.env.REACT_APP_API_URL}/precadastro/pj/step1`;
            axios.post(url, values)
                .then(response => {
                    console.log('Dados enviados com sucesso:', response.data);
                    advanceStep(response.data.id);
                })
                .catch(error => {
                    console.error('Erro ao enviar dados:', error);
                });
        }
    });

    return (
        <>
            <h6 className="ParaEmpresa mx-auto mb-3">PARA SUA EMPRESA</h6>
            <h1>SIMULAÇÃO CONTA DE ALUGUEL</h1>
            <p>Preencha os campos a seguir para iniciar a simulação de adiantamento das contas de aluguel. <br /><strong>É rápido e seguro!</strong></p>

            <form className="mx-auto FormDados" onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12">
                        <input
                            type="text"
                            className="form-control"
                            id="nome"
                            placeholder="Razão Social"
                            {...formik.getFieldProps('nome')}
                        />
                        {formik.touched.nome && formik.errors.nome ? (
                            <div className="text-danger">{formik.errors.nome}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-12 col-lg-12">
                        <input
                            type="text"
                            className="form-control"
                            id="nomeFantasia"
                            placeholder="Nome Fantasia"
                            {...formik.getFieldProps('nomeFantasia')}
                        />
                        {formik.touched.nomeFantasia && formik.errors.nomeFantasia ? (
                            <div className="text-danger">{formik.errors.nomeFantasia}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                        <InputMask
                            mask="99.999.999/9999-99"
                            className="form-control mb-2 text-center"
                            placeholder="CNPJ"
                            {...formik.getFieldProps('cnpj')}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.cnpj && formik.errors.cnpj ? (
                            <div className="text-danger">{formik.errors.cnpj}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                        <InputMask
                            mask="(99) 99999-9999"
                            maskChar={null}
                            className="form-control mb-2 text-center"
                            placeholder="Celular contato"
                            {...formik.getFieldProps('celular')}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.celular && formik.errors.celular ? (
                            <div className="text-danger">{formik.errors.celular}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-12 col-lg-12">
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="name@example.com"
                            {...formik.getFieldProps('email')}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="text-danger">{formik.errors.email}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-12 col-lg-12 mt-4 w-md-100">
                        <button type="submit" className="btn btn-primary">AVANÇAR</button>
                    </div>
                </div>
            </form>
        </>
    );
}

export default Step1Empresa;
