function Choice({ advanceStep, setTipo }) {

    const handleChoice = (tipo) => {
        setTipo(tipo)
        advanceStep();
    }
    return (<>
        <h1>SIMULAÇÃO CONTA DE ALUGUEL</h1>
        <p>Selecione abaixo para quem será a simulação.<br /><strong>É rápido e seguro!</strong></p>

        <div className="mx-auto mt-4">
            <div className="Perfis row" role="group" aria-label="Basic mixed styles example">
            <div className="col-12 col-md-6 col-lg-6 my-2 mx-auto"><button type="button" className="btn ParaVoce w-100" onClick={() => handleChoice('PF')}>Iniciar</button></div>
            {/*<div className="col-12 col-md-6 col-lg-6 my-2 mx-auto"><button type="button" className="btn ParaVoce w-100" onClick={() => handleChoice('PF')}>Para Você</button></div>
            <div className="col-12 col-md-6 col-lg-6 my-2 mx-auto"><button type="button" className="btn ParaEmpresa w-100" onClick={() => handleChoice('Empresa')}>Para sua empresa</button></div>*/}
            </div>
        </div>
    </>
    )
}

export default Choice;