import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Choice from "./Choice";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import axios from "axios";
import Step1Empresa from "./empresa/Step1Empresa";
import Step2Empresa from "./empresa/Step2Empresa";
import Step3Empresa from "./empresa/Step3Empresa";
import Step4Empresa from "./empresa/Step4Empresa";
import Step5Empresa from "./empresa/Step5Empresa";
import Step6Empresa from "./empresa/Step6Empresa";
import Step7Empresa from "./empresa/Step7Empresa";
import Step1Condominio from "./condominio/Step1Condominio";
import Step2Condominio from "./condominio/Step2Condominio";
import Step3Condominio from "./condominio/Step3Condominio";
import Step4Condominio from "./condominio/Step4Condominio";
import Step5Condominio from "./condominio/Step5Condominio";
import Step6Condominio from "./condominio/Step6Condominio";
import Step7Condominio from "./condominio/Step7Condominio";


function Cadastro() {

    const { parceiro } = useParams();
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(0);
    const [submissionId, setSubmissionId] = useState(null);
    const [simulationId, setSimulationId] = useState(0);
    const [finalValue, setFinalValue] = useState(null);    
    const [parceiroId, setParceiroId] = useState();
    const [tipo, setTipo] = useState(null);


    useEffect(() => {
        const verificarParceiro = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/precadastro/parceiro/${parceiro}`);
                if (!response.data) {
                    navigate('/404');
                }
                setParceiroId(response.data);
            } catch (error) {
                console.error('Erro ao verificar a empresa:', error);
                navigate('/404');
            }
        };

        verificarParceiro();
    }, [parceiro, navigate]);

    const nextStep = (id = null) => {
        if (id) {
            setSubmissionId(id); // Atualiza o ID aqui
        }
        setCurrentStep(currentStep + 1);
    };

    const handleSetFinalValue = (value) => {
        setFinalValue(value);
        
    };

    const hundleSetSimulationId = (value) => {
        setSimulationId(value);
        console.log("setSimulationId");
    }

    const handleUrl = () => {
        window.location.href = `/${parceiro}`;
    }
    return (
        <div className="App">
            <div className="row Top g-0">
                <img src="imgs/Logo_EmprestaCapital.svg" alt="Empresta capital" className="mx-auto d-block LogoEmpresta py-4" onClick={handleUrl} style={{ cursor: 'pointer' }} />
            </div>
            <div className="container Larg">
                <div className={`row align-items-end ${tipo == null || tipo === 'PF' ? 'Cabec' : tipo == 'Empresa' ? 'CabecEmpresa' : 'CabecCondominio'}`} >
                    <div className="ConteudoBanner">
                        <img src="imgs/Logo_Alugueis.svg" alt="Alugueis" className="LogoProduto mx-auto row" />
                        <p>Potencialize seu benefício com a Empresta Capital! Simule aqui o recebimento à vista das suas contas de aluguel! </p>
                    </div>
                </div>
                <div className="row">
                    <ul className="nav justify-content-center my-3">
                        {currentStep >= 1 && Array.from({ length: tipo == 'PF' ? 6 : 7 }, (_, i) => (
                            <li className={`nav-item`} key={`id-${i}`}>
                                <a className={`nav-link ${currentStep === i + 1 ? 'active' : ''}`}>{i + 1}</a>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="row SimulaDados">
                    {currentStep === 0 && <Choice advanceStep={nextStep} setTipo={setTipo} />}
                    {tipo == 'PF' && (
                        <>
                            {currentStep === 1 && <Step1 advanceStep={nextStep} parceiroId={parceiroId} />}
                            {currentStep === 2 && <Step2 advanceStep={nextStep} submissionId={submissionId} setFinalValue={handleSetFinalValue} parceiroId={parceiroId} />}
                            {currentStep === 3 && <Step3 advanceStep={nextStep} submissionId={submissionId} />}
                            {currentStep === 4 && <Step4 advanceStep={nextStep} submissionId={submissionId} />}
                            {currentStep === 5 && <Step5 advanceStep={nextStep} submissionId={submissionId} />}
                            {currentStep === 6 && <Step6 url={parceiro} />}
                        </>
                    )
                    }
                    {tipo == 'Empresa' && (
                        <>
                            {currentStep === 1 && <Step1Empresa advanceStep={nextStep} parceiroId={parceiroId} />}
                            {currentStep === 2 && <Step2Empresa advanceStep={nextStep} submissionId={submissionId} simulationId={simulationId} setSimulationId={hundleSetSimulationId} setFinalValue={handleSetFinalValue} parceiroId={parceiroId} />}
                            {currentStep === 3 && <Step3Empresa advanceStep={nextStep} submissionId={submissionId} simulationId={simulationId} setSimulationId={hundleSetSimulationId} />}
                            {currentStep === 4 && <Step4Empresa advanceStep={nextStep} submissionId={submissionId} />}
                            {currentStep === 5 && <Step5Empresa advanceStep={nextStep} submissionId={submissionId} />}
                            {currentStep === 6 && <Step6Empresa advanceStep={nextStep} submissionId={submissionId} />}
                            {currentStep === 7 && <Step7Empresa url={parceiro} />}
                        </>
                    )}
                    {tipo == 'Condominio' && (
                        <>
                            {currentStep === 1 && <Step1Condominio advanceStep={nextStep} parceiroId={parceiroId} />}
                            {currentStep === 2 && <Step2Condominio advanceStep={nextStep} submissionId={submissionId} setFinalValue={handleSetFinalValue} parceiroId={parceiroId} />}
                            {currentStep === 3 && <Step3Condominio advanceStep={nextStep} submissionId={submissionId} />}
                            {currentStep === 4 && <Step4Condominio advanceStep={nextStep} submissionId={submissionId} />}
                            {currentStep === 5 && <Step5Condominio advanceStep={nextStep} submissionId={submissionId} />}
                            {currentStep === 6 && <Step6Condominio advanceStep={nextStep} submissionId={submissionId} />}
                            {currentStep === 7 && <Step7Condominio url={parceiro} />}
                        </>
                    )}
                </div>
                <div className="Footer">
                    <img src="imgs/Empresta-Icon.svg" className="IconEmpresta mx-auto row mt-3" />
                </div>
            </div>
        </div >
    );
}

export default Cadastro;