import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

function Step3Condominio({ advanceStep, submissionId }) {
    const [formattedArrecadacao, setFormattedArrecadacao] = useState('');
    const [formattedFolhaPagamento, setFormattedFolhaPagamento] = useState('');
    const [formattedCota, setFormattedCota] = useState('');
    const [formattedDespesasFixas, setFormattedDespesasFixas] = useState('');
    const [formattedDespesasVariaveis, setFormattedDespesasVariaveis] = useState('');

    const handleCurrencyFormat = (event, field) => {
        let value = event.target.value.replace(/\D/g, '');
        value = value ? Number(value) / 100 : '';
        const formatted = value ? new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2
        }).format(value) : '';

        if (field === 'arrecadacaoMediaMes') {
            setFormattedArrecadacao(formatted);
            formik.setFieldValue('arrecadacaoMediaMes', value.toString());
        } else if (field === 'valorFolhaPagamento') {
            setFormattedFolhaPagamento(formatted);
            formik.setFieldValue('valorFolhaPagamento', value.toString());
        } else if (field === 'valorCotaCondominial') {
            setFormattedCota(formatted);
            formik.setFieldValue('valorCotaCondominial', value.toString());
        } else if (field === 'despesasFixas') {
            setFormattedDespesasFixas(formatted);
            formik.setFieldValue('despesasFixas', value.toString());
        } else if (field === 'despesasVariaveis') {
            setFormattedDespesasVariaveis(formatted);
            formik.setFieldValue('despesasVariaveis', value.toString());
        }
    };

    const formik = useFormik({
        initialValues: {
            cep: '',
            logradouro: '',
            numero: '',
            complemento: '',
            bairro: '',
            municipio: '',
            estado: '',
            telefoneContato: '',
            atividadeEconomica: '',
            dataAbertura: '',
            arrecadacaoMediaMes: '',
            inadimplenciaMediaMes: '',
            numeroBlocos: '',
            numeroApartamentos: '',
            numeroFuncionarios: '',
            valorFolhaPagamento: '',
            valorCotaCondominial: '',
            despesasFixas: '',
            despesasVariaveis: '',
            finalidadeCredito: '',
            razaoSocial: '',
            cnpj: '',
            nomeContato: '',
            emailContato: ''
        },
        validationSchema: Yup.object({
            cep: Yup.string().required('O CEP é obrigatório').matches(/^\d{5}-\d{3}$/, 'CEP inválido'),
            logradouro: Yup.string().required('O logradouro é obrigatório'),
            numero: Yup.string().required('O número é obrigatório'),
            complemento: Yup.string(),
            bairro: Yup.string().required('O bairro é obrigatório'),
            municipio: Yup.string().required('O município é obrigatório'),
            estado: Yup.string().required('O estado é obrigatório'),
            telefoneContato: Yup.string().required('O telefone do contato é obrigatório'),
            atividadeEconomica: Yup.string().required('A atividade econômica é obrigatória'),
            dataAbertura: Yup.string().required('A data de abertura é obrigatória'),
            arrecadacaoMediaMes: Yup.number().required('A arrecadação média é obrigatória').positive('Deve ser um valor positivo'),
            inadimplenciaMediaMes: Yup.number().required('A inadimplência média é obrigatória').positive('Deve ser um valor positivo'),
            numeroBlocos: Yup.number().required('O número de blocos é obrigatório').positive('Deve ser um valor positivo'),
            numeroApartamentos: Yup.number().required('O número de apartamentos é obrigatório').positive('Deve ser um valor positivo'),
            numeroFuncionarios: Yup.number().required('O número de funcionários é obrigatório').positive('Deve ser um valor positivo'),
            valorFolhaPagamento: Yup.number().required('O valor da folha de pagamento é obrigatório').positive('Deve ser um valor positivo'),
            valorCotaCondominial: Yup.number().required('O valor da cota condominial é obrigatório').positive('Deve ser um valor positivo'),
            despesasFixas: Yup.number().required('As despesas fixas são obrigatórias').positive('Deve ser um valor positivo'),
            despesasVariaveis: Yup.number().required('As despesas variáveis são obrigatórias').positive('Deve ser um valor positivo'),
            finalidadeCredito: Yup.string().required('A finalidade do crédito é obrigatória'),
            razaoSocial: Yup.string().required('A razão social é obrigatória'),
            cnpj: Yup.string().required('O CNPJ é obrigatório').matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/, 'CNPJ inválido'),
            nomeContato: Yup.string().required('O nome do contato é obrigatório'),
            emailContato: Yup.string().email('E-mail inválido').required('O e-mail do contato é obrigatório')
        }),
        onSubmit: values => {
            const payload = {
                ...values,
                id: submissionId
            };

            axios.post(`${process.env.REACT_APP_API_URL}/precadastro/condominio/step3`, payload)
                .then(response => {
                    advanceStep();
                })
                .catch(error => console.error('Erro ao enviar dados:', error));
        }
    });

    return (
        <>
            <h6 className="ParaCondominio mx-auto mb-3">PARA SEU CONDOMÍNIO</h6>
            <h1>SIMULAÇÃO CONTA DE ALUGUEL</h1>
            <p>Preencha os campos a seguir para iniciar a simulação de adiantamento das contas de aluguel. <br /><strong>É rápido e seguro!</strong></p>

            <form className="mx-auto FormDados2 mt-3" onSubmit={formik.handleSubmit}>
                <h3>DADOS DO CONDOMÍNIO</h3>
                <div className="row">
                    <div className="col-md-auto col-md-4 col-lg-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="CEP"
                            {...formik.getFieldProps('cep')}
                        />
                        {formik.touched.cep && formik.errors.cep ? (
                            <div className="text-danger">{formik.errors.cep}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-md-12 col-lg-8">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Logradouro"
                            {...formik.getFieldProps('logradouro')}
                        />
                        {formik.touched.logradouro && formik.errors.logradouro ? (
                            <div className="text-danger">{formik.errors.logradouro}</div>
                        ) : null}
                    </div>
                    <div className="col-6 col-md-4 col-lg-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Número"
                            {...formik.getFieldProps('numero')}
                        />
                        {formik.touched.numero && formik.errors.numero ? (
                            <div className="text-danger">{formik.errors.numero}</div>
                        ) : null}
                    </div>
                    <div className="col-6 col-md-4 col-lg-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Complemento"
                            {...formik.getFieldProps('complemento')}
                        />
                    </div>
                    <div className="col-md-auto col-md-4 col-lg-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Bairro"
                            {...formik.getFieldProps('bairro')}
                        />
                        {formik.touched.bairro && formik.errors.bairro ? (
                            <div className="text-danger">{formik.errors.bairro}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-lg-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Município"
                            {...formik.getFieldProps('municipio')}
                        />
                        {formik.touched.municipio && formik.errors.municipio ? (
                            <div className="text-danger">{formik.errors.municipio}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-md-4 col-lg-4">
                        <select
                            className="form-select mb-lg-0 my-1"
                            {...formik.getFieldProps('estado')}
                        >
                            <option value="">Selecione o Estado</option>
                            <option value="AC">Acre</option>
                            <option value="AL">Alagoas</option>
                            <option value="AP">Amapá</option>
                            <option value="AM">Amazonas</option>
                            <option value="BA">Bahia</option>
                            <option value="CE">Ceará</option>
                            <option value="DF">Distrito Federal</option>
                            <option value="ES">Espírito Santo</option>
                            <option value="GO">Goiás</option>
                            <option value="MA">Maranhão</option>
                            <option value="MT">Mato Grosso</option>
                            <option value="MS">Mato Grosso do Sul</option>
                            <option value="MG">Minas Gerais</option>
                            <option value="PA">Pará</option>
                            <option value="PB">Paraíba</option>
                            <option value="PR">Paraná</option>
                            <option value="PE">Pernambuco</option>
                            <option value="PI">Piauí</option>
                            <option value="RJ">Rio de Janeiro</option>
                            <option value="RN">Rio Grande do Norte</option>
                            <option value="RS">Rio Grande do Sul</option>
                            <option value="RO">Rondônia</option>
                            <option value="RR">Roraima</option>
                            <option value="SC">Santa Catarina</option>
                            <option value="SP">São Paulo</option>
                            <option value="SE">Sergipe</option>
                            <option value="TO">Tocantins</option>
                        </select>
                        {formik.touched.estado && formik.errors.estado ? (
                            <div className="text-danger">{formik.errors.estado}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-md-4 col-lg-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Telefone do Contato"
                            {...formik.getFieldProps('telefoneContato')}
                        />
                        {formik.touched.telefoneContato && formik.errors.telefoneContato ? (
                            <div className="text-danger">{formik.errors.telefoneContato}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-lg-8">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Atividade Econômica Principal"
                            {...formik.getFieldProps('atividadeEconomica')}
                        />
                        {formik.touched.atividadeEconomica && formik.errors.atividadeEconomica ? (
                            <div className="text-danger">{formik.errors.atividadeEconomica}</div>
                        ) : null}
                    </div>
                    <div className="col-md-auto col-md-4 col-lg-4 mb-3">
                        <input
                            type="date"
                            className="form-control"
                            placeholder="Data de abertura"
                            {...formik.getFieldProps('dataAbertura')}
                        />
                        {formik.touched.dataAbertura && formik.errors.dataAbertura ? (
                            <div className="text-danger">{formik.errors.dataAbertura}</div>
                        ) : null}
                    </div>
                </div>

                <h3 className='mt-3'>DADOS FINANCEIROS</h3>
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-6">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Arrecadação Média Mês (R$)"
                            value={formattedArrecadacao}
                            onChange={(event) => handleCurrencyFormat(event, 'arrecadacaoMediaMes')}
                        />
                        {formik.touched.arrecadacaoMediaMes && formik.errors.arrecadacaoMediaMes ? (
                            <div className="text-danger">{formik.errors.arrecadacaoMediaMes}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                        <input
                            type="number" min="0" max="100"
                            className="form-control"
                            placeholder="Inadimplência Média Mês (%)"
                            {...formik.getFieldProps('inadimplenciaMediaMes')}
                        />
                        {formik.touched.inadimplenciaMediaMes && formik.errors.inadimplenciaMediaMes ? (
                            <div className="text-danger">{formik.errors.inadimplenciaMediaMes}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-4 col-lg-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Nº de Blocos"
                            {...formik.getFieldProps('numeroBlocos')}
                        />
                        {formik.touched.numeroBlocos && formik.errors.numeroBlocos ? (
                            <div className="text-danger">{formik.errors.numeroBlocos}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-4 col-lg-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Nº de Apartamentos"
                            {...formik.getFieldProps('numeroApartamentos')}
                        />
                        {formik.touched.numeroApartamentos && formik.errors.numeroApartamentos ? (
                            <div className="text-danger">{formik.errors.numeroApartamentos}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-4 col-lg-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Nº de Funcionários"
                            {...formik.getFieldProps('numeroFuncionarios')}
                        />
                        {formik.touched.numeroFuncionarios && formik.errors.numeroFuncionarios ? (
                            <div className="text-danger">{formik.errors.numeroFuncionarios}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Valor de Folha de Pagamento (R$)"
                            value={formattedFolhaPagamento}
                            onChange={(event) => handleCurrencyFormat(event, 'valorFolhaPagamento')}
                        />
                        {formik.touched.valorFolhaPagamento && formik.errors.valorFolhaPagamento ? (
                            <div className="text-danger">{formik.errors.valorFolhaPagamento}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Valor da Cota Condominial"
                            value={formattedCota}
                            onChange={(event) => handleCurrencyFormat(event, 'valorCotaCondominial')}
                        />
                        {formik.touched.valorCotaCondominial && formik.errors.valorCotaCondominial ? (
                            <div className="text-danger">{formik.errors.valorCotaCondominial}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Despesas Fixas (Média Mês)"
                            value={formattedDespesasFixas}
                            onChange={(event) => handleCurrencyFormat(event, 'despesasFixas')}
                        />
                        {formik.touched.despesasFixas && formik.errors.despesasFixas ? (
                            <div className="text-danger">{formik.errors.despesasFixas}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Despesas Variáveis (Média Mês)"
                            value={formattedDespesasVariaveis}
                            onChange={(event) => handleCurrencyFormat(event, 'despesasVariaveis')}
                        />
                        {formik.touched.despesasVariaveis && formik.errors.despesasVariaveis ? (
                            <div className="text-danger">{formik.errors.despesasVariaveis}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-12 col-lg-12 mb-3">
                        <textarea
                            className="form-control"
                            rows="3"
                            placeholder="Finalidade do Crédito"
                            {...formik.getFieldProps('finalidadeCredito')}
                        />
                        {formik.touched.finalidadeCredito && formik.errors.finalidadeCredito ? (
                            <div className="text-danger">{formik.errors.finalidadeCredito}</div>
                        ) : null}
                    </div>
                </div>

                <h3 className="mt-3">DADOS DA ADMINISTRADORA</h3>
                <div className="row">
                    <div className="col-12 col-md-8 col-lg-8">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Razão Social"
                            {...formik.getFieldProps('razaoSocial')}
                        />
                        {formik.touched.razaoSocial && formik.errors.razaoSocial ? (
                            <div className="text-danger">{formik.errors.razaoSocial}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-4 col-lg-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="CNPJ"
                            {...formik.getFieldProps('cnpj')}
                        />
                        {formik.touched.cnpj && formik.errors.cnpj ? (
                            <div className="text-danger">{formik.errors.cnpj}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-8 col-lg-8">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Nome completo do contato"
                            {...formik.getFieldProps('nomeContato')}
                        />
                        {formik.touched.nomeContato && formik.errors.nomeContato ? (
                            <div className="text-danger">{formik.errors.nomeContato}</div>
                        ) : null}
                    </div>
                    <div className="col-12 col-md-4 col-lg-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="E-mail do contato"
                            {...formik.getFieldProps('emailContato')}
                        />
                        {formik.touched.emailContato && formik.errors.emailContato ? (
                            <div className="text-danger">{formik.errors.emailContato}</div>
                        ) : null}
                    </div>

                    <div className="col-md-auto col-md-12 col-lg-12 mt-3">
                        <button type="submit" className="btn btn-primary">avançar</button>
                    </div>
                </div>
            </form>
        </>
    );
}

export default Step3Condominio;
