import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Cadastro from './components/Cadastro';
import Page404 from './components/404';


function App() {

  return (
    <Router>
      <Routes>
        <Route path="/404" element={< Page404 />} />
        <Route path="/:parceiro" element={<Cadastro />} />
      </Routes>
    </Router>
  );
}

export default App;
