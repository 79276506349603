import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

function Step2Condominio({ advanceStep, submissionId, setFinalValue, parceiroId }) {
    const [formattedValue, setFormattedValue] = useState('');
    const [showOptions, setShowOptions] = useState(false);
    const [paymentOptions, setPaymentOptions] = useState({ twelveMonths: '', twentyFourMonths: '' });
    const [errors, setErrors] = useState({ termoDeUso: false, compartilhamentoDados: false, termoFidelidade: false });

    const formik = useFormik({
        initialValues: {
            valorMedio: '',
            termoDeUso: false,
            compartilhamentoDados: false,
            termoFidelidade: false
        },
        validationSchema: Yup.object({
            valorMedio: Yup.number()
                .required("O valor da conta de aluguel é obrigatório")
                .positive("O valor deve ser positivo")
                .min(800, "O valor mínimo é de R$ 800,00")
        }),
        onSubmit: values => {
            const payload = {
                parceiroId: parceiroId,
                valorMedio: parseFloat(values.valorMedio),
                id: submissionId
            };

            axios.post(`${process.env.REACT_APP_API_URL}/precadastro/condominio/step2`, payload)
                .then(response => {
                    setPaymentOptions({
                        twelveMonths: formatCurrency(response.data.valorFinal12x),
                        twentyFourMonths: formatCurrency(response.data.valorFinal24x)
                    });
                    setShowOptions(true);
                })
                .catch(error => console.error('Erro ao enviar dados:', error));
        }
    });

    const handleCurrencyFormat = event => {
        let value = event.target.value.replace(/\D/g, '');
        value = value ? Number(value) / 100 : '';
        const formatted = value ? new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2
        }).format(value) : '';
        setFormattedValue(formatted);
        formik.setFieldValue('valorMedio', value.toString());
    };

    const formatCurrency = value => {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2
        }).format(value);
    };

    const selectOption = (option) => {
        const allTermsAccepted = formik.values.termoDeUso && formik.values.compartilhamentoDados && formik.values.termoFidelidade;
        if (!allTermsAccepted) {
            setErrors({
                termoDeUso: !formik.values.termoDeUso,
                compartilhamentoDados: !formik.values.compartilhamentoDados,
                termoFidelidade: !formik.values.termoFidelidade
            });
            return; // Prevent further execution if terms are not accepted
        }

        const parcelamento = option === '12' ? '12' : '24';
        const payload = {
            parceiroId: parceiroId,
            valorMedio: parseFloat(formik.values.valorMedio),
            id: submissionId,
            parcelamento: parcelamento
        };

        axios.post(`${process.env.REACT_APP_API_URL}/precadastro/condominio/step2`, payload)
            .then(response => {
                setFinalValue(option === '12' ? paymentOptions.twelveMonths : paymentOptions.twentyFourMonths);
                advanceStep();
            })
            .catch(error => console.error('Erro ao enviar dados:', error));
    };

    return (
        <>
            <h6 className="ParaCondominio mx-auto mb-3">PARA SEU CONDOMÍNIO</h6>
            <h1>SIMULAÇÃO ANTECIPAÇÃO DE ALUGUEL</h1>
            <p>Preencha os campos a seguir para iniciar a simulação de adiantamento das contas de aluguel. <br /><strong>É rápido e seguro!</strong></p>
            <form className="mx-auto FormDados" onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col-md-12">
                        <input
                            type="text"
                            className="form-control"
                            id="valorMedio"
                            placeholder="Valor do Aluguel"
                            value={formattedValue}
                            onChange={handleCurrencyFormat}
                        />
                        {formik.touched.valorMedio && formik.errors.valorMedio ? (
                            <div className="text-danger">{formik.errors.valorMedio}</div>
                        ) : null}
                    </div>
                    <div className="col-md-12 mt-3">
                        <button type="submit" className="btn btn-secondary">Simular</button>
                    </div>

                    {showOptions && (
                        <>
                            <div className="col-md-12 mt-5">
                                <div className="btn-group" role="group" aria-label="Basic example">
                                    <div className="col-12 col-lg-6 mb-3 mb-lg-auto">
                                        <div className="DetBotaoSimula">
                                            <h3>12 meses</h3>
                                            <button type="button" className="btn btn-primary" onClick={() => selectOption('12')}>QUERO ESSE!</button>
                                            <p>Até <strong>{paymentOptions.twelveMonths}</strong> antecipando 12 meses</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <div className="DetBotaoSimula">
                                            <h3>24 meses</h3>
                                            <button type="button" className="btn btn-primary" onClick={() => selectOption('24')}>QUERO ESSE!</button>
                                            <p>Até <strong>{paymentOptions.twentyFourMonths}</strong> antecipando 24 meses</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mt-3">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="termoDeUso" {...formik.getFieldProps('termoDeUso')} />
                                    <label className="form-check-label" htmlFor="termoDeUso">
                                        Estou de acordo com o Termo de Uso
                                    </label>
                                    {errors.termoDeUso && <div className="text-danger">Aceitar os termos de uso é obrigatório</div>}
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="compartilhamentoDados" {...formik.getFieldProps('compartilhamentoDados')} />
                                    <label className="form-check-label" htmlFor="compartilhamentoDados">
                                        Autorizo o compartilhamento de meus dados entre Administradora e Empresta Capital
                                    </label>
                                    {errors.compartilhamentoDados && <div className="text-danger">Autorizar o compartilhamento de dados é obrigatório</div>}
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="termoFidelidade" {...formik.getFieldProps('termoFidelidade')} />
                                    <label className="form-check-label" htmlFor="termoFidelidade">
                                        Aceito manter fidelidade junto a Administradora pelo prazo da operação escolhido
                                    </label>
                                    {errors.termoFidelidade && <div className="text-danger">Aceitar o termo de fidelidade é obrigatório</div>}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </form>
        </>
    );
}

export default Step2Condominio;
