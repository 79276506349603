import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from "axios";
import InputMask from 'react-input-mask';
import { cpf as cpfValidator } from 'cpf-cnpj-validator';

function Step1({ advanceStep, parceiroId }) {
    const validationSchema = Yup.object({
        nome: Yup.string().required("O nome é obrigatório"),
        cpf: Yup.string().required("CPF obrigatório").test('is-cpf-valid', 'CPF inválido', value => cpfValidator.isValid(value)),
        celular: Yup.string().required("Celular obrigatório")
            .matches(/^\(\d{2}\)\s(\d{4,5}-\d{4})$/, "Celular inválido"),
        email: Yup.string().email("Email inválido").required("Email obrigatório"),
    });

    const formik = useFormik({
        initialValues: {
            nome: '',
            cpf: '',
            celular: '',
            email: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log('Submitting values:', values);
            const payload = {
                ...values,
                parceiroId: parceiroId
            };
            console.log('Final payload to send:', payload);
            const url = `${process.env.REACT_APP_API_URL}/precadastro/step1`;
            axios.post(url, payload)
                .then(response => {
                    console.log('Dados enviados com sucesso:', response.data);
                    advanceStep(response.data.id);
                })
                .catch(error => {
                    console.error('Erro ao enviar dados:', error);
                });
        }
    });

    return (
        <form className="mx-auto FormDados" onSubmit={formik.handleSubmit}>
            <h1>SIMULAÇÃO ANTECIPAÇÃO DE ALUGUEL</h1>
            <p>Preencha os campos a seguir para iniciar a simulação de adiantamento de suas contas de aluguel. <strong>É rápido e seguro!</strong></p>
            <div className="row">
                <div className="col-md-12">
                    <input
                        type="text"
                        className="form-control"
                        id="nome"
                        placeholder="Digite seu nome completo"
                        {...formik.getFieldProps('nome')}
                    />
                    {formik.touched.nome && formik.errors.nome ? (
                        <div className="text-danger mt-0 mb-2">{formik.errors.nome}</div>
                    ) : null}
                </div>
                <div className="col-md-12">
                    <InputMask
                    type="text"
                        mask="999.999.999-99"
                        className="form-control"
                        placeholder="CPF"
                        {...formik.getFieldProps('cpf')}
                        onChange={formik.handleChange}
                    />
                    {formik.touched.cpf && formik.errors.cpf ? (
                        <div className="text-danger">{formik.errors.cpf}</div>
                    ) : null}
                </div>
                <div className="col-md-12">
                    <InputMask
                    type="text"
                        mask="(99) 99999-9999"
                        maskChar={null}
                        className="form-control text-center"
                        placeholder="Celular"
                        {...formik.getFieldProps('celular')}
                        onChange={formik.handleChange}
                    />
                    {formik.touched.celular && formik.errors.celular ? (
                        <div className="text-danger">{formik.errors.celular}</div>
                    ) : null}
                </div>
                <div className="col-md-12">
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="xxx@email.com"
                        {...formik.getFieldProps('email')}
                    />
                    {formik.touched.email && formik.errors.email ? (
                        <div className="text-danger">{formik.errors.email}</div>
                    ) : null}
                </div>
                <div className="col-md-12 mt-3 mb-4">
                    <button type="submit" className="btn btn-primary">AVANÇAR</button>
                </div>
                <h6 className="Obs d-none">Inicialmente este benefício está disponível somente para contas de aluguel no valor uma média mínima de R$ 800,00/mês.</h6>
            </div>

        </form>
    );
}

export default Step1;
