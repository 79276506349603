function Page404() {


    return (
        <>
            404
        </>
    );
}

export default Page404;